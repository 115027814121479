const ua = navigator.userAgent.toLowerCase();
// 微信客户端
const isWx = !!/micromessenger/.test(ua);
// 阿里系客户端
const isAli = /aliapp/i.test(ua);
// 支付宝客户端
const isAliPay = /aliapp\(ap/i.test(ua);
// 手淘、天猫客户端
const isAliClient = /aliapp\(t[bm]/i.test(ua);
// Android客户端
const isAndroid = ua.indexOf('android') > -1 || ua.indexOf('linux') > -1;
// iPhone客户端
const isIPhone = ua.indexOf('iphone') > -1;
// iPad客户端
const isIPad = ua.indexOf('ipad') > -1;
// 钉钉环境
const isDingding = /DingTalk/i.test(ua);
// 是否是PC端
const isPc =
  !/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua);

export {
  isWx,
  isAli,
  isAliPay,
  isAliClient,
  isAndroid,
  isIPhone,
  isIPad,
  isDingding,
  isPc
};
