import { defineStore } from 'pinia';

export const useMessageStore = defineStore('demo', {
  state: () => ({
    noticeUnread: 0, // 通知未读条数
    trendsUnread: 0 // 服务动态未读条数
  }),
  actions: {
    setNoticeUnread(num: number) {
      this.noticeUnread = num;
    },
    setTrendsUnread(num: number) {
      this.trendsUnread = num;
    },
    // 读取一条未读的通知数据
    readOneNotice() {
      if (this.noticeUnread > 0) {
        this.noticeUnread--;
      }
    }
  }
});
