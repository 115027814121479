/**
 * @author MKF
 * @desc 公共枚举
 * @date 2023/8/25
 */
export const languageMap = [
  { name: '简体', code: 'zh-CN' },
  { name: '繁體', code: 'zh-TW' },
  { name: 'English', code: 'en-US' }
];

export const WEEK_MAP = ['一', '二', '三', '四', '五', '六', '日'];
