import microApp from '@micro-zoe/micro-app';
import { Router } from 'vue-router';
import { KT_LOT_CODE, KT_TOKEN, UNITY_LOCALE } from './localKey';
import Cookies from 'js-cookie';
import { getNewToken } from '@/api/global';
import { getPrimaryDomain } from '@/utils';
import {
  useGlobalStore,
  useMenuStore,
  useTagsStore,
  useUserStore
} from '@/stores';
import publishMessage from './publishMessage';
import { findCurrentSelectedMenu } from '@/utils';

/**
 * @param desc 该函数只处理 重构的新页面的跳转，包括云助手（微前端页面）
 */
const jump = async (
  path: string,
  router: Router,
  info = {},
  refresh?: boolean,
  pathName?: string,
  queryStr?: string,
  urlType?: number
) => {
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const menuStore = useMenuStore();
  const tagsStore = useTagsStore();
  // 缓存所有展开后的路由
  const setAllTagsKeepAlive = (appName: string) => {
    let keepAlivePages: string[] = tagsStore.tags
      .filter(item => item.keepAlive !== false)
      .map(({ route: { name } }) => name);
    /**
     * 收入统计等页面
     * 详情查看需要带参数查询，需要刷新页面（过滤掉当前详情页，保证每次进入都刷新）
     */
    if (refresh) {
      keepAlivePages = keepAlivePages.filter(item => item !== pathName);
    }
    // 将需要缓存的tag发送给子应用
    microApp.setData(appName, {
      type: 'KEEP_ALIVE_NAMES',
      payload: keepAlivePages
    });
  };
  // 设置cookie
  const setCookie = async (info: any) => {
    const primaryDomain = getPrimaryDomain();
    // 避免子应用重载时lotId还未更新
    Cookies.remove(KT_LOT_CODE, { domain: primaryDomain });
    Cookies.set(KT_LOT_CODE, info.lotId || '', {
      expires: 365,
      domain: primaryDomain
    });
    const globalStore = useGlobalStore();
    // TODO: 是否groupid也是可以
    const res = await getNewToken(globalStore.panelId, urlType);
    Cookies.remove(UNITY_LOCALE, { domain: primaryDomain });
    Cookies.remove(KT_TOKEN, { domain: primaryDomain });
    Cookies.set(KT_TOKEN, res.data || '', {
      expires: 365,
      domain: primaryDomain
    });
  };
  const menuItem = findCurrentSelectedMenu(
    path,
    'pcPath',
    menuStore.transformMenuList
  );
  const defaultInfo = {
    userInfo: userStore.userInfo,
    menuId: menuItem ? menuItem.menuId : '',
    ...info
  };

  globalStore.setChildDefaultInfo(defaultInfo);
  if (path.includes('settlefront-new') || path.includes('nkc-new')) {
    await setCookie(info);
  } else if (path.includes('kos-new')) {
    // 页面跳转会触发组织树重新渲染(将最新的Info发送给子应用)
    publishMessage('kos-new', {
      action: 'switchOrg',
      ...defaultInfo
    });
  } else if (path.includes('group-parking')) {
    // 页面跳转会触发组织树重新渲染(将最新的Info发送给子应用)
    publishMessage('group-parking', {
      action: 'switchOrg',
      ...defaultInfo
    });
  } else if (path.includes('pcm')) {
    publishMessage('pcm', {
      action: 'switchOrg',
      ...defaultInfo
    });
  } else if (path.includes('service-ticket')) {
    publishMessage('service-ticket', {
      action: 'switchOrg',
      ...defaultInfo
    });
  } else if (path.includes('ums-yc')) {
    publishMessage('ums-yc', {
      action: 'switchOrg',
      ...defaultInfo
    });
  } else if (path.includes('yongce-unityp-pages')) {
    publishMessage('yongce-unityp-pages', {
      action: 'switchOrg',
      ...defaultInfo
    });
  } else if (path.includes('road-side')) {
    publishMessage('road-side', {
      action: 'switchOrg',
      ...defaultInfo
    });
  }
  const appName = path.split('/')[1];

  setAllTagsKeepAlive(appName);
  microApp.setData(appName, {
    type: 'AUTH_BUTTONS',
    payload: menuStore.buttons
  });
  router.replace(queryStr ? path + '?' + queryStr : path);
  microApp.setData(appName, {
    type: 'CHILD_SKIP',
    payload: queryStr ? path + '?' + queryStr : path
  });
  window.localStorage.setItem(
    'orgInfo',
    JSON.stringify(globalStore.$state.orgInfo)
  );
  tagsStore.setPrevPath(path);
};

export default jump;
