const throttle = {
  mounted: (el: HTMLElement, binding: any) => {
    // 节流时间
    let throttleTime = binding.value;
    if (!throttleTime) {
      // 用户若不设置节流时间，则默认2s
      throttleTime = 2000;
    }
    let cbFun: number | null;
    el.addEventListener(
      'click',
      event => {
        if (!cbFun) {
          // 第一次执行
          cbFun = setTimeout(() => {
            cbFun = null;
          }, throttleTime);
        } else {
          event && event.stopImmediatePropagation();
        }
      },
      true
    );
  }
};

export default throttle;
