import { old_unityp } from '@/pages/MicroApp/config';
import { useTagsStore, useUserStore } from '@/stores';
import microApp from '@micro-zoe/micro-app';
import Cookies from 'js-cookie';
import { ACC_LOCALE } from '@/utils/localKey';

/**
 * @des 数组对象，根据属性排序
 * @param {Array} arr 数组对象
 * @param {*} key 排序的属性
 */
export const arrObjSortByKey = (arr: any[], key: string) => {
  const handle = () => {
    return function (a: any, b: any) {
      const val1 = a[key];
      const val2 = b[key];
      return val1 - val2;
    };
  };
  arr.sort(handle());
};
/**
 *@des 树形结构数据，根据属性排序
 * @param {Array} treeData 树形结构数据
 * @param {*} sortKey 排序的key
 */
export const toSortTreeData = (treeData: any, sortKey: string) => {
  arrObjSortByKey(treeData, sortKey);
  treeData.forEach((item: any) => {
    if (item.childrenMenuList && item.childrenMenuList.length) {
      toSortTreeData(item.childrenMenuList, sortKey);
    }
  });
};
/**
 * @des 将一维数组转成树形结构
 * @param {Array} data 一维数组
 * @param {Object} attributes 对象属性，表明树形结构的关系{id: "当前id的key", parentId: "父级id的key"}
 */
export const toTreeData = (
  data: any,
  attributes = {
    id: 'menuId',
    parentId: 'pid'
  }
) => {
  const resData = data;
  const tree = [];

  // 找寻根节点
  for (let i = 0; i < resData.length; i++) {
    if (
      resData[i][attributes.parentId] === '' ||
      resData[i][attributes.parentId] === null ||
      resData[i][attributes.parentId] === 'null'
    ) {
      tree.push(treeObj(resData[i]));
      resData.splice(i, 1);
      i--;
    }
  }

  run(tree);

  // 找寻子树
  function run(chiArr: any[]) {
    if (resData.length !== 0) {
      for (let i = 0; i < chiArr.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (chiArr[i][attributes.id] === resData[j][attributes.parentId]) {
            const obj = treeObj(resData[j]);
            chiArr[i].childrenMenuList.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        run(chiArr[i].childrenMenuList);
      }
    }
  }
  function treeObj(originObj: any) {
    // 对象深拷贝
    const obj: any = {};
    for (const key in originObj) {
      const val = originObj[key];
      obj[key] =
        typeof val === 'object' && val !== null && key !== 'route'
          ? treeObj(val)
          : val;
    }
    // 对象新增children键值，用于存放子树
    obj['childrenMenuList'] = [];
    return obj;
  }

  toSortTreeData(tree, 'sortNo');
  return tree;
};

/**
 * @des 获取项目的主域名，不带端口号，如192.168.1.1:8080 => 192.168.1.1；
 * @returns {String} 主域名
 */
export const getPrimaryDomain = () => {
  const url = window.location.origin;
  const p = /(\.(\w)+\.(com|cn))|(\d{1,4}\.\d{1,4}\.\d{1,4}.\d{1,4})/;
  return url.match(p) ? url.match(p)![0] : 'localhost';
};

export const getBrowser = () => {
  const u = navigator.userAgent;
  // pc终端浏览器版本信息
  const isIEsm = u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1; // IE11以下
  const isEdge = u.indexOf('Edge') > -1 && !isIEsm; // ie Edge
  const isIE11 = u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1; // ie11
  const isIE = isIEsm || isEdge || isIE11; // 所有ie
  return {
    isIEsm,
    isEdge,
    isIE11,
    isIE
  };
};

/**
 * @des 数组对象去重
 * @param {Array} 源数组
 * @param {String} 根据对象的哪个属性进行去重
 * @return {Array} 返回处理后的数组
 */
export const arrObjectUnique = (arr: any[], key: string): any[] => {
  const result = [];
  const obj: any = {};
  for (let i = 0; i < arr.length; i++) {
    if (!obj[arr[i][key]]) {
      result.push(arr[i]);
      obj[arr[i][key]] = true;
    }
  }
  return result;
};

/**
 * @des 数组去重
 * @param {Array} arr 源数组
 * @return {Array} 去重后的数组
 */
export const arrUnique: <T>(val: T[]) => T[] = arr => {
  return Array.from(new Set(arr));
};

export function raf(fn: any) {
  let prev = Date.now();

  function fallback(fn: any) {
    const curr = Date.now();
    const ms = Math.max(0, 16 - (curr - prev));
    const id = setTimeout(fn, ms);
    prev = curr + ms;
    return id;
  }
  const root = window;

  const iRaf = root.requestAnimationFrame || fallback;

  const iCancel = root.cancelAnimationFrame || root.clearTimeout;
  return iRaf.call(root, fn);
}

/**
 * @description 所有菜单跳转前经过这个判断
 */
export const isIframeView = (path: string) => {
  if (old_unityp.includes(path)) {
    console.log('当前跳转页面---集团老页面6个');
    // 如果基座老页面
    return true;
  }
  console.log('path', path);

  if (
    path.includes('kos-new') ||
    path.includes('nkc-new') ||
    path.includes('group-parking') ||
    path.includes('fcfront') ||
    path.includes('pcm') ||
    path.includes('settlefront-new') ||
    path.includes('service-ticket') ||
    path.includes('ums-yc') ||
    path.includes('yongce-unityp-pages') ||
    path.includes('road-side')
  ) {
    console.log('当前跳转页面---永策以及云助手重构页面');

    return false;
  } else {
    console.log('当前跳转页面---需要iframe嵌套的老页面');
    return true;
  }
};

/**
 * @desc 查找指定的菜单
 * @param {string} value 需要查找的值
 * @param {string} key 需要查找的值对应的key
 * @param  menuList 需要查找的数组
 * */
export const findCurrentSelectedMenu = (
  value: string,
  key: string,
  menuList: any[]
) => {
  let currentMenu: any = {};
  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i]?.[key] === value) {
      currentMenu = menuList[i];
      break;
    }
    if (menuList[i]?.childrenMenuList?.length) {
      const obj: any = findCurrentSelectedMenu(
        value,
        key,
        menuList[i]?.childrenMenuList
      );
      if (obj?.[key] === value) {
        currentMenu = obj;
        break;
      }
    }
  }
  return currentMenu;
};

// 清空cookie中的token和kt-token，原生方法
export const clearCookies = () => {
  document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'kt-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

/**
 *@desc 筛选组织树中的组织
 * */
// 需要处理的页面路由名
export const pathNameList = ['UserNew', 'RoleNew', 'GroupNew'];
// 筛选函数
export const filterGroup = (treeData: any[]) => {
  if (!treeData?.length) return treeData;
  const arr: any[] = [];
  treeData.forEach((item: any, index) => {
    if (!item?.lotId) arr.push(item);
    if (item?.childrenMenuList?.length) {
      arr[index] = {
        ...item,
        childrenMenuList: filterGroup(item.childrenMenuList)
      };
    }
  });
  return arr;
};

// 获取指定的url参数
export const getQueryVariable = (variable: string) => {
  // console.log('location', window.location);
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    console.log(pair, 'xx');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

export const parse_url = (url: string) => {
  const temp1 = url.split('?');
  const pram = temp1[1];
  const keyValue = pram.split('&');
  const obj = {};
  for (let i = 0; i < keyValue.length; i++) {
    const item = keyValue[i].split('=');
    const key = item[0];
    const value = item[1];
    //@ts-ignore
    obj[key] = value;
  }
  return obj;
};

export function getAllUrlParam(url: string) {
  const UrlParams: Record<string, string> = {};
  if (!url) {
    return UrlParams;
  }
  if (url.indexOf('?') !== -1) {
    const str = url.split('?')[1]; // 获取url中"?"符后的字串
    const strs: string[] = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      UrlParams[strs[i].split('=')[0]] = decodeURIComponent(
        strs[i].split('=')[1]
      );
    }
  }
  return UrlParams;
}

// 获取token
export const getCurrentToken = () => {
  return (
    useUserStore().tempToken ||
    Cookies.get('token') ||
    localStorage.getItem('token')
  );
};
