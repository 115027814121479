import { createI18n } from 'vue-i18n';
import KTUnity_zh_CN from '@kt/unity-locale/lang/zh_CN';
import KTUnity_en_US from '@kt/unity-locale/lang/en_US';
import KTUnity_zh_TW from '@kt/unity-locale/lang/zh_TW';
import KTRequest_zh_CN from '@kt/request/dist/locale/lang/zh_CN';
import KTRequest_en_US from '@kt/request/dist/locale/lang/en_US';
import KTRequest_zh_TW from '@kt/request/dist/locale/lang/zh_TW';

//内置国际化资源
// @ts-ignore
import zhCN from '../../public/i18n/messages_zh_CN.json';
// @ts-ignore
import enUS from '../../public/i18n/messages_en_US.json';
// @ts-ignore
import zhHK from '../../public/i18n/messages_zh_TW.json';
import { getLanguage } from 'shared/utils';
/*
  根据浏览器系统语言设置语言，如果有存在本地localStorage 则读取，
  如果没有则默认为简体中文
*/
const getUrlParams = (name: any) => {
  if (name === null || name === 'undefined') {
    return null;
  }
  const searchStr = decodeURI(window.location.href);
  const infoIndex = searchStr.indexOf(name + '=');
  if (infoIndex === -1) {
    return null;
  }
  let searchInfo = searchStr.substring(infoIndex + name.length + 1);
  const tagIndex = searchInfo.indexOf('&');
  if (tagIndex !== -1) {
    searchInfo = searchInfo.substring(0, tagIndex);
  }
  return searchInfo;
};

const urlLang = getUrlParams('yc-language') || '';
// 先判断本地有没有存储当前语言
const locLang = getLanguage();
const lang = urlLang || locLang;
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  messages: {
    'zh-CN': {
      ...zhCN,
      ...KTUnity_zh_CN,
      ...KTRequest_zh_CN
    },
    'en-US': {
      ...enUS,
      ...KTUnity_en_US,
      ...KTRequest_en_US
    },
    'zh-TW': {
      ...zhHK,
      ...KTUnity_zh_TW,
      ...KTRequest_zh_TW
    }
  },
  locale: lang,
  fallbackLocale: 'zh-CN'
});

function initLang(lang: string) {
  let language: any = {};
  try {
    // 内置了中文，对新资源合并
    if (lang === 'zh-CN') {
      language = Object.assign(lang, {
        ...zhCN,
        ...KTUnity_zh_CN,
        ...KTRequest_zh_CN
      });
    } else if (lang === 'en-US') {
      language = Object.assign(lang, {
        ...enUS,
        ...KTUnity_en_US,
        ...KTRequest_en_US
      });
    } else if (lang === 'zh-TW') {
      language = Object.assign(lang, {
        ...zhHK,
        ...KTUnity_zh_TW,
        ...KTRequest_zh_TW
      });
    }
    i18n.global.setLocaleMessage(lang, language);
  } catch (error) {
    // 外置语言包获取失败，默认中文展示
    language = Object.assign(lang, {
      ...zhCN,
      ...KTUnity_zh_CN,
      ...KTRequest_zh_CN
    });
    i18n.global.setLocaleMessage(lang, language);
  }
}

// initLang(lang);
export default i18n;
