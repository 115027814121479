import { defineStore } from 'pinia';
import router from '@/router';
export interface Tag {
  name: string;
  route: any;
  isRemoveCurrent?: boolean;
  selected?: boolean;
  closable?: boolean;
  keepAlive?: boolean;
  [k: string]: any;
}
type IAction = '' | 'add' | 'move_menu' | 'move_tag';
export const useTagsStore = defineStore('tags', {
  state: () => ({
    defaultTag: {} as any,
    tags: [] as Tag[],
    // index 改变时会在TagsNav里进行watch，并且调用this.$router.replace进行路由切换
    index: 0,

    isDel: false,

    isReplace: false,
    replaceIndex: 0,

    isRemoveCurrent: false,
    currentIndex: 0,
    action: '' as IAction,
    prevPath: '' as string,
    isRemoveAll: false
  }),
  getters: {
    /**
     * @desc 获取当前选中的tag
     */
    getSelectedTag: state => {
      return state.tags[state.index];
    }
  },
  actions: {
    setDefaultTag(params: any) {
      this.defaultTag = params || {};
    },
    resetStatus() {
      this.tags = [];
      this.action = '';
      this.isDel = false;
      this.isReplace = false;
      this.index = 0;
    },
    setTags(tags: Tag[]) {
      this.tags = tags;
    },
    setPrevPath(prevPath: string) {
      this.prevPath = prevPath;
    },
    setAction(action: IAction) {
      this.action = action;
    },
    setRemoveAll(status: boolean) {
      this.isRemoveAll = status;
    },
    initTags(data: Tag) {
      this.tags = [];
      this.tags.push(data);
    },
    /**
     * @desc 设置tag当前的index
     */
    setTagsIndex(index: number) {
      this.index = index;
    },
    /**
     * @desc 添加tag，
     * 场景：isRemoveCurrent为新增了新的tag之后，删除掉当前选中的tag
     */
    addTag(tag: Tag) {
      // 获取当前选中的tag，用来切换到其他tag的时候，删除这个tag
      if (tag.isRemoveCurrent) {
        this.isRemoveCurrent = true;
        this.currentIndex = this.index;
      }
      const {
        route: { name, path }
      } = tag;
      // if (tag.route.path === this.prevPath) {
      //   // 点击同一个菜单, 通知子应用刷新
      //   tag?.route?.path && router.push('/refresh');
      // }
      const index = this.getTagIndexByRouteName(name);
      if (index !== -1) {
        this.action = 'move_menu';
        const tagItem = this.getTagByRouteName(name);
        // 判断path是否一致，不一致删除之前的tag，重新push新的tag
        if (tagItem?.route.path !== path) {
          // 先新增一个tag，然后在TagsNav的watch，删除需要替换掉的tag
          this.tags.forEach(item => {
            item.selected = false;
            item.keepAlive = true;
          });
          this.tags.push({ ...tag, keepAlive: false });
          this.setTagsIndex(this.tags.length - 1);

          // 标记让watch可以执行删除
          this.isReplace = true;
          this.replaceIndex = index;
          return;
        }
        this.tags.forEach(item => {
          item.selected = false;
          item.keepAlive = true;
        });
        this.selectTag(index);
      } else {
        // 重置选中状态
        this.tags.forEach(item => {
          item.selected = false;
          item.keepAlive = true;
        });
        this.tags.push({ ...tag, keepAlive: false });
        this.setTagsIndex(this.tags.length - 1);
        // 左侧菜单切换exist的tag
        this.action = 'add';
      }
    },

    /**
     * @desc 添加tag
     * 可能会出现打开了一个tag，然后在其他页面打开相同的tag，内容是不一样的，但是还会移动到之前的那个tag，导致内容没有更新
     * 要先删掉之前的tag，然后push一个新的tag
     */
    addTagForce(tag: Tag) {
      const { tags } = this;
      const {
        route: { name }
      } = tag;
      const index = this.getTagIndexByRouteName(name);
      if (index !== -1) {
        this.setTagsIndex(index);
      } else {
        // 重置选中状态
        tags.forEach(item => (item.selected = false));
        tags.push(tag);
        this.setTagsIndex(tags.length - 1);
      }
    },
    /**
     * @desc 选中tag，标志selecteded为true
     */
    selectTag(index: number) {
      const { tags } = this;
      tags.forEach((item, i) => {
        item.selected = i === index;
        item.keepAlive = true;
      });
      this.setTagsIndex(index);
    },
    /**
     * @desc 删除tag，
     * 由于改变index的方法是在$nextTick才会起作用，所以这里的index要判断好
     */
    removeTag({ index, isRemoveCurrent = false }: any) {
      const { tags } = this;
      tags.splice(index, 1);
      // 赋值当前选中itme 正确的index
      if (isRemoveCurrent) {
        // 如果是删除当前的item，就 index-1
        this.setTagsIndex(index - 1);
      } else {
        const index = tags.findIndex(item => item.selected);
        this.setTagsIndex(index === -1 ? 0 : index);
      }
      // 移除删除标记
      this.isDel = false;

      // 移除替换tag标记
      this.isReplace = false;
      this.replaceIndex = 0;

      // 移除addTag的时候，删除当前tag标记
      this.isRemoveCurrent = false;
      this.currentIndex = 0;
    },
    /**
     * @desc 删除tag，自动跳转至 "下一个" tag
     * 判断该tag是否为已经选中的tag
     * 判断该tag是否有后一个元素，
     * 先做选中其他tag的动作，再删除点击删除的tag
     * 整个流程与TagsNav组件的watch有交互
     */
    removeSelectNextTag(index: number) {
      // 标记目前在删除
      this.isDel = true;
      const { tags } = this;
      if (tags[index].selected) {
        if (index + 1 > tags.length - 1) {
          this.setTagsIndex(index - 1);
          this.selectTag(index - 1);
        } else {
          this.setTagsIndex(index + 1);
          this.selectTag(index + 1);
        }
      } else {
        this.removeTag({ index });
      }
    },
    /**
     * @desc 删除其他tag
     */
    removeOtherTag() {
      // 保留当前选中的item和首页
      this.tags = this.tags.filter(
        (item, i) =>
          item.route.name === this.defaultTag.pathName || i === this.index
      );
      const newIndex = this.index === 0 ? 0 : this.tags.length - 1;
      this.selectTag(newIndex);
    },
    /**
     * @desc 删除所有tag
     */
    removeAllTag() {
      this.tags = this.tags.filter(
        item => item.route.name === this.defaultTag.pathName
      );
      this.selectTag(0);
      this.setRemoveAll(true);
    },
    /**
     * @desc 场景：删除当前的tag，直接index - 1，指向上一个tag
     */
    removeCurrentTag() {
      this.removeTag({
        index: this.index,
        isRemoveCurrent: true
      });
    },
    /**
     * @desc 通过routeName获取tagIndex
     */
    getTagIndexByRouteName(name: string) {
      return this.tags.findIndex(item => item.route.name === name);
    },
    /**
     * @desc 通过routeName获取tag
     */
    getTagByRouteName(name: string) {
      return this.tags.find(item => item.route.name === name);
    },
    /**
     * @desc 通过index获取tag
     */
    getTagByIndex(index: number) {
      return this.tags[index];
    },
    /**
     * @desc 设置指定tag的keep-alive为true
     */
    setKeepAlive(name: string) {
      const index = this.getTagIndexByRouteName(name);
      if (index === -1) return;
      this.tags[index].keepAlive = true;
    }
  }
});
