import { defineStore } from 'pinia';
import { Loading } from '@kt/unity-components';

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    spinning: false
  }),
  actions: {
    show() {
      this.spinning = true;
      Loading.show();
    },
    hide() {
      this.spinning = false;

      Loading.hide();
    }
  }
});
