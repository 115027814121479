/**
 * @author DEJAVU
 * @desc 页面加载初始化，请求组织结构，菜单选项以及用户信息
 * @date 2022-04-13 16:04:27
 */
import { useMenuStore, useUserStore, useGroupStore } from '@/stores';
import { getMenuListByGroup } from '@/api/global';
import { isNonEmptyArray } from 'group-parking/src/utils/is';
//@ts-ignore
import { saasMenuList } from '@/hooks/data';

export const pullTree = (arr: any[]) => {
  if (!arr) return [];
  let arrNew: any[] = [];
  arr.forEach(item => {
    if (item.childrenMenuList && item.childrenMenuList.length) {
      arrNew = arrNew.concat(pullTree(item.childrenMenuList));
    }
    arrNew.push(item);
  });
  return arrNew;
};
export const changeItem = (arr: any[]) => {
  if (!arr) return [];
  const arrNew: any = [];
  arr.forEach(item => {
    item.pathName = item.pcPathName;
    item.path = item.pcPath;
    item.children = item.childrenMenuList || [];
    item.url = item.pcUrl;
    // 大屏
    if (item.menuTag === 3) {
      useMenuStore().setScreenList(
        item.children.map((ele: any) => ({
          url: ele.pcUrl,
          title: ele.title
        }))
      );
      return;
    }
    if (isNonEmptyArray(item.childrenMenuList)) {
      changeItem(item.childrenMenuList);
    }
    arrNew.push(item);
  });
  return arrNew;
};
const usePageInit = (isSetMenu = false) => {
  const menuStore = useMenuStore();
  const userStore = useUserStore();
  const groupStore = useGroupStore();
  const init = async () => {
    // 接口拆分，避免多次alert 401
    const info = await userStore.getUserInfo();
    if (!info) return [];
    const { data } = await getMenuListByGroup({ groupId: info.groupId });
    const { buttonList, groupTree } = data;

    // saas集团菜单取本地配置
    const menuList =
      info?.groupId === 'eebcc33062914185a92751280d0036de'
        ? changeItem(saasMenuList)
        : changeItem(data?.menuList || []);

    menuStore.setOriginMenuTree(
      info?.groupId === 'eebcc33062914185a92751280d0036de'
        ? saasMenuList
        : data?.menuList || []
    );

    const allOneList = pullTree(menuList || []);
    groupStore.setOriginGroup(groupTree);
    const initResult = await Promise.all([
      userStore.getUserGroupList(),
      menuStore.getMenuList(allOneList || []),
      menuStore.getButtonList(
        (buttonList || []).map((item: any) => item.pcPermissionValue)
      )
    ]);
    initResult.unshift(info);
    menuStore.setTransformMenuList(allOneList);
    return initResult;
  };
  return {
    init
  };
};

export default usePageInit;
