import {
  UrlTarget,
  useGlobalStore,
  useMenuStore,
  useTagsStore,
  useUserStore
} from '@/stores';
import kosNew from '@/pages/MicroApp/Kos.vue';
import nkcNew from '@/pages/MicroApp/Nkc.vue';
import FindCar from '@/pages/MicroApp/FindCar.vue';
import Pcm from '@/pages/MicroApp/Pcm.vue';
import Settlefront from '@/pages/MicroApp/Settlefront.vue';
import ServiceTicket from '@/pages/MicroApp/ServiceTicket.vue';
import UmsYc from '@/pages/MicroApp/UmsYc.vue';
import gpNew from '@/pages/MicroApp/GroupParking.vue';
import UnitypPages from '@/pages/MicroApp/UnitypPages.vue';
import Roadside from '@/pages/MicroApp/RoadSide.vue';
import IframePage from '@/pages/IframeView/IframeNew.vue';
import useGroupTree from '@/hooks/useGroupTree';
import router from '@/router';
import DefaultLayout from '@/layout/Default.vue';
import microApp from '@micro-zoe/micro-app';
import { RouteRecordRaw } from 'vue-router';
import { isNonEmptyArray } from 'group-parking/src/utils/is';
import { systemPages } from '@/router/systemPages';

interface IUrlListProps {
  children?: any;
  id: string;
  path: string;
  pathName: string;
  remark: string;
  url: string;
  urlName: string;
  urlType: number;
}
enum IUrlType {
  'yc' = 1,
  '6x',
  '5x',
  'lc',
  'third'
}
export const getStoreMenuMap = (treeMenuList: UrlTarget[]) => {
  let curItem;
  const setItem = (arr: any[], k: string | number, level1?: string) => {
    for (let i = 0, len = arr.length; i < len; i++) {
      curItem = arr[i];
      curItem.value = !level1 ? k + '-' + i : i + '';
      if (curItem.childrenMenuList && curItem.childrenMenuList.length > 0) {
        // 替换key值名
        setItem(curItem.childrenMenuList, curItem.value);
      }
    }
  };
  let obj: any = {};
  const list = treeMenuList.reduce((o: any, item: any, i: number) => {
    obj = item.childrenMenuList;
    // 处理obj
    setItem(obj, i, 'level1');
    o[i + ''] = obj;
    return o;
  }, {});
  // 过滤侧边栏不含子节点的空文件夹
  // Object.keys(list).forEach(index => {
  //   list[index] = list[index].filter((item: any) => {
  //     return (
  //       item.childrenMenuList.length ||
  //       (!item.childrenMenuList.length &&
  //         item.pcIcon !== 'folder-open' &&
  //         item.pcIicon !== 'folder')
  //     );
  //   });
  // });
  return list;
};

/**
 * @des 将树形结构，标上key值
 * @param {Array} arr 设置key的数组
 */
export const treeDataSetFlag = (arr: any[]) => {
  let num = 0;
  const treeObj = (arr: any[], pKey?: string) => {
    arr.forEach((item: any, index: number) => {
      const key = pKey ? pKey + '.' + ++index : ++num;
      item.key = key;
      if (item.childrenMenuList && item.childrenMenuList.length) {
        treeObj(item.childrenMenuList, item.key);
      }
      if (item.children?.length) {
        treeObj(item.children, item.key);
      }
    });
  };
  treeObj(arr);
};

/**
 * @des 将一维数组转成树形结构
 * @param {Array} data 一维数组
 * @param {Object} attributes 对象属性，表明树形结构的关系{id: "当前id的key", parentId: "父级id的key"}
 */

export const toTreeData = (
  data: any,
  attributes = {
    id: 'menuId',
    parentId: 'pid'
  }
) => {
  const resData = JSON.parse(JSON.stringify(data));
  const tree = [];

  // 找寻根节点
  for (let i = 0; i < resData.length; i++) {
    if (
      resData[i][attributes.parentId] === '' ||
      resData[i][attributes.parentId] === null ||
      resData[i][attributes.parentId] === 'null'
    ) {
      tree.push(treeObj(resData[i]));
      resData.splice(i, 1);
      i--;
    }
  }
  run(tree);
  // 找寻子树
  function run(chiArr: any[]) {
    if (resData.length !== 0) {
      for (let i = 0; i < chiArr.length; i++) {
        for (let j = 0; j < resData.length; j++) {
          if (chiArr[i][attributes.id] === resData[j][attributes.parentId]) {
            const obj = treeObj(resData[j]);
            chiArr[i].childrenMenuList.push(obj);
            resData.splice(j, 1);
            j--;
          }
        }
        run(chiArr[i].childrenMenuList);
      }
    }
  }
  function treeObj(originObj: any) {
    // 对象深拷贝
    const obj = {};
    for (const key in originObj) {
      const val = originObj[key];
      //@ts-ignore
      obj[key] =
        typeof val === 'object' &&
        val !== null &&
        key !== 'route' &&
        key !== 'urlList' &&
        key !== 'lotList' &&
        key !== 'fullList' &&
        key !== 'treeData' &&
        key !== 'groupList'
          ? treeObj(val)
          : val;
    }
    // 对象新增children键值，用于存放子树
    //@ts-ignore
    obj['childrenMenuList'] = [];
    return obj;
  }

  toSortTreeData(tree, 'sortNo');
  return tree;
};

/**
 *@des 树形结构数据，根据属性排序
 * @param {Array} treeData 树形结构数据
 * @param {*} sortKey 排序的key
 */
const toSortTreeData = (treeData: any, sortKey: string) => {
  arrObjSortByKey(treeData, sortKey);
  treeData.forEach((item: any) => {
    if (item.children && item.children.length) {
      toSortTreeData(item.children, sortKey);
    }
  });
};

/**
 * @des 数组对象，根据属性排序
 * @param {Array} arr 数组对象
 * @param {*} key 排序的属性
 */
const arrObjSortByKey = (arr: any[], key: string) => {
  const handle = () => {
    return function (a: any, b: any) {
      const val1 = a[key];
      const val2 = b[key];
      return val1 - val2;
    };
  };
  arr.sort(handle());
};

/**
 * @author DEJAVU
 * @desc 配置微前端以及老页面iframe路由
 * @date 2022-04-14 17:59:47
 */

export const setDymaicRoutes = (originMenuList: UrlTarget[]) => {
  const cacheArr = [] as any[];

  const addRoute = (arr: any[]) => {
    if (!arr.length) return;
    arr.forEach(item => {
      // 无组织树
      if (!item.displayType || item.displayType === 0) {
        cacheArr.push(handleMenuSelect(item));
        if (item.childrenMenuList?.length) {
          addRoute(item.childrenMenuList);
        }
      } else {
        // 有组织树
        cacheArr.push(handleHasOrgMenu(item));
        if (item.childrenMenuList?.length) {
          addRoute(item.childrenMenuList);
        }
      }
    });
  };
  const systemMenu = systemPagesTransfer(systemPages);
  addRoute(originMenuList.concat(systemMenu));
  router.addRoute({
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  });
};
// 添加动态路由
const addDynamciRoute = (menuItem: any) => {
  const componentsMap = (path: string) => {
    if (path.includes('kos-new')) {
      return {
        component: kosNew,
        path
      };
    } else if (path.includes('nkc-new')) {
      return {
        component: nkcNew,
        path
      };
    } else if (path.includes('group-parking')) {
      return {
        component: gpNew,
        path
      };
    } else if (path.includes('fcfront')) {
      return {
        component: FindCar,
        path
      };
    } else if (path.includes('pcm')) {
      return {
        component: Pcm,
        path
      };
    } else if (path.includes('settlefront-new')) {
      return {
        component: Settlefront,
        path
      };
    } else if (path.includes('service-ticket')) {
      return {
        component: ServiceTicket,
        path
      };
    } else if (path.includes('ums-yc')) {
      return {
        component: UmsYc,
        path
      };
    } else if (path.includes('yongce-unityp-pages')) {
      return {
        component: UnitypPages,
        path
      };
    } else if (path.includes('road-side')) {
      return {
        component: Roadside,
        path
      };
    } else {
      return {
        component: IframePage
      };
    }
  };
  const { title = '', pcPath = '', pathName = '', urlName } = menuItem;
  if (!pcPath) return;
  const componentInfo = componentsMap(pcPath);
  const baseRoute = {
    name: pathName,
    path: pcPath,
    meta: {
      title
    },
    ...componentInfo
  };
  const globalStore = useGlobalStore();
  if (globalStore.cacheRoutes.includes(baseRoute.name)) {
    // 已添加过路由，不需要再次添加
    return;
  }
  globalStore.setCacheRoutes(baseRoute.name);
  const record = {
    path: '/',
    component: DefaultLayout,
    children: [baseRoute]
  };
  router.addRoute(record);
};
// 菜单跳转逻辑（这里只处理无组织的情况）
// 全业态下无组织，永策url+第三方
// 全业态仅路侧/全业态仅封闭，无组织只返回永策

export const handleMenuSelect = (
  menuItem: any
): null | undefined | IUrlListProps => {
  const { displayType } = menuItem;
  // 是否新开窗开打开页面
  if (displayType) return null;
  let cacheItem = {} as IUrlListProps;
  // 无组织，直接菜单进行跳转

  cacheItem = {
    ...menuItem
  };
  addDynamciRoute(cacheItem);
  return cacheItem;
};

// 处理有组织树的菜单跳转
export const handleHasOrgMenu = (menuItem: any) => {
  const { displayType } = menuItem;
  if (!displayType || displayType === 0) return;

  // const { getTreeData } = useGroupTree();

  let cacheItem: any = {};
  // 下面合并res，后面组织树处理需要defaultSelectNode
  cacheItem = { ...menuItem };
  // 永策链接默认
  // 这里要对所有的path，进行动态路由的添加，否则切换组织无法加载
  addDynamciRoute(cacheItem);
  return cacheItem;
};

// 过滤掉菜单keepalive

// 注册大屏路由
export const registerBigScreenMenu = (params: any[]) => {
  (params || []).forEach(item => {
    const arr = item.url.split('/unityp');
    if (arr.length > 1 || item.url.includes('group-parking/')) {
      router.addRoute({
        path: arr[1],
        name: arr[1],
        component: gpNew,
        meta: {
          title: item.title
        }
      });
    }
  });
};

// 系统菜单配置
export const systemPagesTransfer = (arr: Array<RouteRecordRaw>): any[] => {
  if (!isNonEmptyArray(arr)) return [];
  return arr.map((item, index) => ({
    title: item?.meta?.title,
    pcPathName: item.name,
    pathName: item.name,
    pcPath: item.path,
    path: item.path,
    pcUrl: window.origin + '/unityp' + item.path,
    pid: '',
    pcJumpType: 0,
    supMobile: false,
    remark: '',
    supPc: true,
    mobileIcon: '',
    displayType: 0,
    clickType: 0,
    pcSortNo: 5,
    pcUrlBelongsTo: 1,
    enableShow: 1,
    pcIcon: 'zuiliang',
    pageApply: 0,
    menuType: 1,
    mobileUrl: '',
    enableStatus: 1,
    menuTag: 0,
    childrenMenuList: []
  }));
};
