/**
 * @author DEJAVU
 * @desc 处理组织树相关逻辑
 * @date 2022-04-19 16:42:40
 */
import { useGroupStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { isNonEmptyArray } from 'group-parking/src/utils/is';
import { cloneDeep } from 'lodash';

interface menuInfoType {
  // 组织车场是否可点击 1-完整组织控件 2-展示组织 3-展示组织、项目 4-展示组织、车场 5-展示项目、车场
  displayType: number;
  // 组织车场是否可点击 0-都可点击 1-仅组织可点击 2-仅车场可点击 3-仅项目可点击 4-仅组织和项目可点击
  clickType: number;
  pcUrlBelongsTo: number;
  // 页面使用 0-全业态 1-路侧 2-封闭
  pageApply: number;
}
const useGroupTree = () => {
  const groupStore = useGroupStore();
  const { originGroup } = storeToRefs(groupStore);

  // 车场禁用
  const isLotDisabled = (clickType: number) => [1, 3, 4].includes(clickType);

  // 组织禁用
  const isGroupDisabled = (clickType: number) => [2, 3].includes(clickType);

  // 项目禁用
  const isProjectDisabled = (clickType: number) => [1, 2].includes(clickType);

  /**
   * @desc 生成当前菜单的组织树数据
   * @param menuInfo 菜单信息
   * @param data 原始组织树数据
   */
  const traverseTree = (
    menuInfo: menuInfoType,
    data = [originGroup.value]
  ): any[] => {
    return data
      .filter(item => (menuInfo.displayType === 2 ? item.type === 1 : true)) // 只展示组织
      .map(item => {
        // 筛选出要显示的车场 pageApply 0-全展示 1路侧 2封闭   lotType 1封闭 2路侧
        const newLotList = [1, 4, 5].includes(menuInfo.displayType)
          ? (item.lotList || [])
              .filter(
                (val: any) =>
                  val.lotName &&
                  (menuInfo.pageApply === 0 ||
                    val.lotType === (menuInfo.pageApply === 1 ? 2 : 1))
              )
              .map((val: any) => ({
                ...val,
                name: val.lotName,
                id: val.lotId,
                parentId: item.groupId,
                disabled: isLotDisabled(menuInfo.clickType),
                type: 2,
                urlType: menuInfo.pcUrlBelongsTo
              }))
          : [];

        const { childrenGroupList, ...props } = item;
        const isTopGroup = item.groupId === originGroup.value.groupId;
        // 4-展示组织、车场
        if (menuInfo.displayType === 4 && item.type === 2) return newLotList;

        // 5-展示项目、车场
        if (menuInfo.displayType === 5 && item.type === 1 && !isTopGroup)
          return traverseTree(menuInfo, childrenGroupList || []);

        return {
          ...props,
          isTopGroup,
          disabled:
            item.type === 1
              ? isGroupDisabled(menuInfo.clickType)
              : isProjectDisabled(menuInfo.clickType),
          type: 1,
          // 组织类型 1 普通组织 2项目组织
          groupType: item.type,
          name: item.groupName,
          id: item.groupId,
          urlType: menuInfo.pcUrlBelongsTo,
          lotList: (item.lotList || []).concat(),
          children: [
            ...newLotList,
            ...traverseTree(menuInfo, childrenGroupList || [])
          ].sort((a, b) => (a?.groupId && b?.lotId ? -1 : 1))
        };
      })
      .flat();
  };

  // 查找第一个可用的节点
  const findAvailableNode = (arr: any[]): any => {
    let obj = null;
    for (const item of arr || []) {
      if (item.disabled === false) {
        obj = cloneDeep(item);
        break;
      }
      if (isNonEmptyArray(item.children)) {
        const res = findAvailableNode(item.children);
        if (res?.disabled === false) {
          obj = cloneDeep(res);
          break;
        }
      }
    }
    return obj;
  };

  // orgType对应关系
  // orgType 1-完整组织控件 2-仅组织可点击 3-仅车场可点击 4-不展示车场 5-不展示项目
  const correspondenceData: Record<
    number,
    { displayType: number; clickType: number }
  > = {
    0: {
      displayType: 0,
      clickType: 0
    },
    1: {
      displayType: 1,
      clickType: 0
    },
    2: {
      displayType: 1,
      clickType: 4
    },
    3: {
      displayType: 1,
      clickType: 2
    },
    4: {
      displayType: 3,
      clickType: 0
    },
    5: {
      displayType: 4,
      clickType: 0
    }
  };

  return {
    traverseTree,
    findAvailableNode,
    correspondenceData
  };
};

export default useGroupTree;
