/**
 * @author MKF
 * @desc 消息中心接口
 * @date 2023/6/27
 */
import request from '@kt/request';

export default class MessageCenterAPI {
  static unread(data: { groupId: string; userId: any; sendTo?: number }) {
    return request.post('message-center/message/unread', data);
  }
}
