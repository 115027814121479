/**
 * @author MKF
 * @desc group-parking系统页面路由
 * @date 2023/9/20
 */
import { RouteRecordRaw } from 'vue-router';
import gpNew from '@/pages/MicroApp/GroupParking.vue';

export const systemPages: Array<RouteRecordRaw> = [
  {
    path: '/group-parking/notificationManage',
    name: 'NotificationManage',
    component: gpNew,
    meta: {
      title: '通知管理'
    }
  },
  {
    path: '/group-parking/tempReportDetail',
    name: 'TempReportDetail',
    component: gpNew,
    meta: {
      title: '月报详情'
    }
  },
  {
    path: '/group-parking/parkingRevenueStatement-detail',
    name: 'ParkingRevenueStatementDetail',
    component: gpNew,
    meta: { title: '收入详情' }
  },
  {
    path: '/group-parking/trafficFlowStatistics-detail',
    name: 'TrafficFlowStatisticsDetail',
    component: gpNew,
    meta: { title: '车流统计详情' }
  }
];
