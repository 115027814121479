import { defineStore } from 'pinia';
import { bifurcateBy } from '../../../shared/utils/index';
import {
  treeDataSetFlag,
  toTreeData,
  setDymaicRoutes,
  registerBigScreenMenu,
  systemPagesTransfer
} from '../utils/menu';
import { useGlobalStore } from './global';
import microApp from '@micro-zoe/micro-app';
import { useToPage } from '@/hooks/useToPage';
import { useTagsStore } from '@/stores/tags';
import { cloneDeep } from 'lodash';
import { systemPages } from '@/router/systemPages';
//@ts-ignore
export interface UrlTarget {
  name?: string;
  path?: string;
  url?: string;
  groupList?: any;
  lotList?: any;
  title: string;
  key: string;
  [k: string]: any;
}
export interface SlideBarMenuList {
  key: string;
  icon?: string;
  title?: string;
  children?: SlideBarMenuList[];
  [k: string]: any;
}

// 递归处理树
const filterTree = (data: any) => {
  const newTree = data.filter((x: any) => x.enableShow !== 0);
  newTree.forEach(
    (x: any) =>
      x.childrenMenuList &&
      (x.childrenMenuList = filterTree(x.childrenMenuList))
  );
  return newTree;
};

export const useMenuStore = defineStore('permission', {
  state: () => ({
    MENU_MAP: [], // 位置索引对应子菜单
    screenList: [], // 右上角大数据看板列表
    noMenuFlag: false, // 有些车场没有菜单，做此标识
    treeMenuList: [] as UrlTarget[], // 一级菜单tree数据
    originMenuList: [] as UrlTarget[], // 最初原始菜单数据
    slideBarMenuList: [] as SlideBarMenuList[], // 侧边栏二级-三级数据
    hasCollapsed: false, // 侧边栏是否有折叠
    slideBarCollapsedClick: false, // 点击折叠侧边栏的标识
    slideBarCollapsed: false, // 左侧菜单栏，是否折叠
    iframeBaseRoute: [], // 所有路由对象
    orgTreeMenuList: [] as UrlTarget[], // 所有含有组织树的菜单
    transformMenuList: [] as any, // 获取urlList重新组装menuList,
    currentMenuItem: {} as any,
    indexName: '', // 一级菜单名称
    buttons: [] as string[],
    originMenuTree: [] as any[] // 原始菜单树
  }),
  getters: {
    getUrlByRouteName: state => (routeName: string) => {
      return (
        state.originMenuList.find((item: any) => item.pathName === routeName) ||
        {}
      );
    },
    getUrlByPath: state => (path: string) => {
      return (
        state.originMenuList.find((item: UrlTarget) => item.path === path) || {
          title: '',
          key: ''
        }
      );
    },
    getRootMenuByRouteName: state => (routeName: string) => {
      let menu: UrlTarget = null!;
      const getKey = (arr: UrlTarget[], topMenu?: UrlTarget) => {
        for (const item of arr) {
          if (item.pathName === routeName) {
            menu = topMenu || item;
            return;
          }
          if (item.childrenMenuList && item.childrenMenuList.length) {
            getKey(item.childrenMenuList, topMenu || item);
          }
        }
      };
      getKey(state.treeMenuList);
      return menu;
    },
    getMenuKeyByRouteName: state => (routeName: string) => {
      let key = '';
      const getKey = (arr: UrlTarget[]) => {
        for (const item of arr) {
          if (item.pcPathName === routeName) {
            key = item.key;
            return;
          }
          if (item.childrenMenuList && item.childrenMenuList.length) {
            getKey(item.childrenMenuList);
          }
        }
      };
      getKey(state.treeMenuList);
      return key;
    },
    getMenuItemByKey: state => (key: string) => {
      let menuItem = null;
      const getMenuByKey = (arr: SlideBarMenuList[]) => {
        for (const item of arr) {
          if (item.key === key) {
            menuItem = item;
            return;
          }
          if (item.childrenMenuList && item.childrenMenuList.length) {
            getMenuByKey(item.childrenMenuList);
          }
        }
      };
      getMenuByKey(state.slideBarMenuList);
      console.log('state.slideBarMenuList', state.slideBarMenuList);

      return menuItem;
    }
  },
  actions: {
    /**
     * @desc 重置
     */
    resetMenuData() {
      this.MENU_MAP = [];
      this.screenList = [];
      this.treeMenuList = [];
      this.originMenuList = [];
      this.slideBarMenuList = [];
      this.iframeBaseRoute = [];
      this.orgTreeMenuList = [];
      this.transformMenuList = [];
      this.noMenuFlag = false;
    },
    setSlideBarCollapsed(status: boolean) {
      this.hasCollapsed = true;
      this.slideBarCollapsedClick = true;
      this.slideBarCollapsed = status;
    },
    setSlideBarMenu(list: SlideBarMenuList[]) {
      this.slideBarMenuList = list;
    },
    setIframeBaseRoute(iArr: any) {
      this.iframeBaseRoute = iArr;
    },
    setStoreMenuMap(obj: any) {
      // 过滤不展示的菜单
      const menu = cloneDeep(obj);
      for (const i in menu) {
        if (menu[i].length > 0) {
          menu[i] = filterTree(menu[i]);
        }
      }
      this.MENU_MAP = menu;
    },
    setNoMenuFlag(status: boolean) {
      this.noMenuFlag = status;
    },
    setTreeMenuList(list: UrlTarget[]) {
      this.treeMenuList = list;
    },
    setButtons(list: Array<string>) {
      this.buttons = list;
    },
    setScreenList(list: any) {
      this.screenList = list;
      registerBigScreenMenu(this.screenList);
    },
    setOriginMenuList(list: UrlTarget[]) {
      this.originMenuList = list;
      const cacheList = this.originMenuList.filter(
        (f: UrlTarget) => f.menuType === '1'
      );
      this.setHasOrgTreeMenuList(cacheList);
    },
    setHasOrgTreeMenuList(cacheList: UrlTarget[]) {
      this.orgTreeMenuList = cacheList;
    },
    async getMenuList(data: any[]) {
      if (!data) {
        this.setNoMenuFlag(true);
        return;
      }
      this.setOriginMenuList(JSON.parse(JSON.stringify(data)));
      setDymaicRoutes(JSON.parse(JSON.stringify(data)));
      this.setNoMenuFlag(false);
      (() => {
        const showMenuList = JSON.parse(JSON.stringify(data)).filter(
          (item: any) => item.menuTag !== 2
        );

        // 属性配置信息
        const attributes = {
          id: 'menuId',
          parentId: 'pid'
        };
        const treeMenuList = toTreeData(showMenuList, attributes);

        treeDataSetFlag(treeMenuList); // 设置key值
        // 对一级菜单下，没有子集的特殊处理，如：首页
        treeMenuList.forEach((item: any) => {
          if (!item.childrenMenuList?.length) {
            const obj = JSON.parse(JSON.stringify(item));
            obj.key = obj.key + '.1';
            item.childrenMenuList = [];
            item.childrenMenuList.push(obj);
            // 删除一级菜单的部分属性
            delete item.path;
            delete item.pathName;
          }
        });
        this.setTreeMenuList(treeMenuList as UrlTarget[]);
        !treeMenuList.length && this.setNoMenuFlag(true);
      })();
      return data;
    },
    async getButtonList(data: [string]) {
      this.setButtons(data);
      return data;
    },

    setTransformMenuList(list: any) {
      //@ts-ignore
      const jumpPath = useToPage();
      const obj = (list || []).find((item: any) => item.path === jumpPath());
      useTagsStore().setDefaultTag(obj);

      // 系统页面路由注册
      this.transformMenuList = (list || []).concat(
        systemPagesTransfer(systemPages)
      );
    },
    setCurrentMenuItem(obj: any) {
      this.currentMenuItem = obj;
    },
    setIndexName(name: string) {
      this.indexName = name;
    },
    setOriginMenuTree(arr: any[]) {
      this.originMenuTree = arr || [];
    }
  }
});
