/**
 * @author MKF
 * @desc 组织树相关
 * @date 2023/7/20
 */
import { defineStore } from 'pinia';

export const useGroupStore = defineStore('group', {
  state: () => ({
    // 组织树原始数据
    originGroup: {} as any,
    // 转换之后的树形数据
    groupTree: [] as any[],
    // 集团下所有车场
    allLotList: [] as any[],
    // 集团下所有组织
    allGroupList: [] as any[]
  }),
  actions: {
    setOriginGroup(param: any) {
      this.originGroup = param;
      this.setGroupTree(this.originGroup ? [this.originGroup] : []);
    },
    // 生成组织树结构，获取集团下所有组织和车场列表
    setGroupTree(param: any[]) {
      const toTreeData = (arr: any[]) => {
        if (!arr.length) return [];
        const newArr: any[] = [];
        arr.forEach(item => {
          const { childrenGroupList, lotList, groupId } = item;
          this.allLotList = this.allLotList.concat(lotList || []);
          groupId && this.allGroupList.push(item);
          if (Array.isArray(childrenGroupList) && childrenGroupList.length) {
            item.children = [
              ...toTreeData(childrenGroupList),
              ...(lotList || [])
            ];
            newArr.push(item);
          }
        });
        return newArr;
      };
      this.groupTree = toTreeData(param);
    }
  }
});
