/**
 * @author MKF
 * @desc data
 * @date 2023/7/12
 */
export const saasMenuList = [
  {
    menuId: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
    title: 'saas管理',
    pid: '',
    supPc: true,
    supMobile: false,
    menuType: 0,
    menuTag: 0,
    pcIcon: 'zuian',
    mobileIcon: '',
    pcJumpType: null,
    mobileJumpType: null,
    pcUrlBelongsTo: null,
    mobileUrlBelongsTo: null,
    pcUrl: '',
    mobileUrl: '',
    pcPath: '',
    mobilePath: null,
    pcPathName: '',
    mobilePathName: null,
    pcSortNo: 5,
    mobileSortNo: null,
    pageApply: null,
    displayType: null,
    clickType: null,
    pcPermissionValue: null,
    mobilePermissionValue: null,
    remark: '',
    enableStatus: 1,
    childrenMenuList: [
      {
        menuId: '1c4f4a4c4e5f4522bd95f7458367c0b0',
        title: '模板角色管理',
        pid: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
        supPc: true,
        supMobile: false,
        menuType: 1,
        menuTag: 0,
        pcIcon: 'fangda',
        mobileIcon: '',
        pcJumpType: 0,
        mobileJumpType: null,
        pcUrlBelongsTo: 1,
        mobileUrlBelongsTo: null,
        pcUrl:
          'http://yongce-pro-test.keytop.cn:30123/unityp/group-parking/saasRole',
        mobileUrl: '',
        pcPath: '/group-parking/saasRole',
        mobilePath: null,
        pcPathName: 'saasRole',
        mobilePathName: null,
        pcSortNo: 3,
        mobileSortNo: null,
        pageApply: 0,
        displayType: 0,
        clickType: 0,
        pcPermissionValue: null,
        mobilePermissionValue: null,
        remark: '',
        enableStatus: 1,
        childrenMenuList: null
      },
      {
        menuId: '5b0b9718b7c449d2a1aa93b960bb5017',
        title: '用户管理',
        pid: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
        supPc: true,
        supMobile: false,
        menuType: 1,
        menuTag: 0,
        pcIcon: 'yonghuguanli',
        mobileIcon: '',
        pcJumpType: 0,
        mobileJumpType: null,
        pcUrlBelongsTo: 1,
        mobileUrlBelongsTo: null,
        pcUrl:
          'http://yongce-pro-test.keytop.cn:30123/unityp/group-parking/saasUser',
        mobileUrl: '',
        pcPath: '/group-parking/saasUser',
        mobilePath: null,
        pcPathName: 'saasUser',
        mobilePathName: null,
        pcSortNo: 2,
        mobileSortNo: null,
        pageApply: 0,
        displayType: 0,
        clickType: 0,
        pcPermissionValue: null,
        mobilePermissionValue: null,
        remark: '',
        enableStatus: 1,
        childrenMenuList: null
      },
      {
        menuId: '6355d2e1808d45a3bfbe5e7730ba3a68',
        title: '菜单管理',
        pid: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
        supPc: true,
        supMobile: false,
        menuType: 1,
        menuTag: 0,
        pcIcon: 'chechangchushihua',
        mobileIcon: '',
        pcJumpType: 0,
        mobileJumpType: null,
        pcUrlBelongsTo: 1,
        mobileUrlBelongsTo: null,
        pcUrl: 'http://61.171.113.40:30123/unityp/group-parking/saasMenu',
        mobileUrl: '',
        pcPath: '/group-parking/saasMenu',
        mobilePath: null,
        pcPathName: 'saasMenu',
        mobilePathName: null,
        pcSortNo: 0,
        mobileSortNo: null,
        pageApply: 0,
        displayType: 0,
        clickType: 0,
        pcPermissionValue: null,
        mobilePermissionValue: null,
        remark: '',
        enableStatus: 1,
        childrenMenuList: null
      },
      {
        menuId: 'e0c0f8f3b08f4f31ab0fd2dcaac92237',
        title: '集团管理',
        pid: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
        supPc: true,
        supMobile: false,
        menuType: 1,
        menuTag: 0,
        pcIcon: 'xiangzuo',
        mobileIcon: '',
        pcJumpType: 0,
        mobileJumpType: null,
        pcUrlBelongsTo: 1,
        mobileUrlBelongsTo: null,
        pcUrl:
          'http://yongce-pro-test.keytop.cn:30123/unityp/group-parking/saasGroup',
        mobileUrl: '',
        pcPath: '/group-parking/saasGroup',
        mobilePath: null,
        pcPathName: 'saasGroup',
        mobilePathName: null,
        pcSortNo: 4,
        mobileSortNo: null,
        pageApply: 0,
        displayType: 0,
        clickType: 0,
        pcPermissionValue: null,
        mobilePermissionValue: null,
        remark: '',
        enableStatus: 1,
        childrenMenuList: null
      },
      {
        menuId: 'be45e953a327417081d38d9e47792279',
        title: '消息模板',
        pid: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
        supPc: true,
        supMobile: false,
        menuType: 1,
        menuTag: 0,
        pcIcon: 'lishishuju',
        mobileIcon: '',
        pcJumpType: 0,
        mobileJumpType: null,
        pcUrlBelongsTo: 1,
        mobileUrlBelongsTo: null,
        pcUrl:
          'http://yongce-pro-test.keytop.cn:30123/unityp/group-parking/messageTemplate',
        mobileUrl: '',
        pcPath: '/group-parking/messageTemplate',
        mobilePath: null,
        pcPathName: 'MessageTemplate',
        mobilePathName: null,
        pcSortNo: 5,
        mobileSortNo: null,
        pageApply: 0,
        displayType: 0,
        clickType: 0,
        pcPermissionValue: null,
        mobilePermissionValue: null,
        remark: '',
        enableStatus: 1,
        childrenMenuList: null
      },
      {
        menuId: 'saasVersionManage',
        title: 'saas版本管理',
        pid: 'a26fc17ad88f40e6a0bbe57b90bfe83a',
        supPc: true,
        supMobile: false,
        menuType: 1,
        menuTag: 0,
        pcIcon: 'lishishuju',
        mobileIcon: '',
        pcJumpType: 0,
        mobileJumpType: null,
        pcUrlBelongsTo: 1,
        mobileUrlBelongsTo: null,
        pcUrl:
          'http://yongce-pro-test.keytop.cn:30123/unityp/group-parking/saasVersionManage',
        mobileUrl: '',
        pcPath: '/group-parking/saasVersionManage',
        mobilePath: null,
        pcPathName: 'SaasVersionManage',
        mobilePathName: null,
        pcSortNo: 6,
        mobileSortNo: null,
        pageApply: 0,
        displayType: 0,
        clickType: 0,
        pcPermissionValue: null,
        mobilePermissionValue: null,
        remark: '',
        enableStatus: 1,
        childrenMenuList: null
      }
    ]
  }
];
