export default {
  /**
   * @desc 正则表达式判断字符串中是否有空格
   */
  isHasSpace(str) {
    return /\s+/.test(str);
  },
  /**
   * @desc 必须以字母开头，且以字母、数字、下划线(_)随意组合
   */
  isW(str) {
    return /^[a-zA-Z]{1}\w*$/.test(str);
  },
  /**
   * @desc 验证手机号
   * @returns {Boolean} true/false true为正确的手机号
   */
  isPhone(str) {
    return /^((\+?[0-9]{1,4})|(\(\+86\)))?(13[0-9]|14[5-9]|15[0-35-9]|16[67]|17[0-8]|18[0-9]|19[8-9]|191)\d{8}$/.test(
      str
    );
  },
  /**
   * @desc 验证邮箱
   * @returns {Boolean} true/false true为正确的邮箱
   */
  isEmail(str) {
    return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(
      str
    );
  },
  /**
   * @des 6-20位有特殊符号、数字字母组成的密码
   * @param {String} str
   */
  isPwd(str) {
    const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}');
    // return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z.*[~!@#$%^&*()_+=|<>,.?/:;'\\[\\]{}\"]+.*]{8,20}$/.test(str);
    return pwdRegex.test(str);

    // return /^(?![0-9]+$)(?![a-zA-Z]+$)[ 0-9A-Za-z_ ]{8,20}$/.test(str);
    // return /^[0-9a-zA-Z!@#$%&*-+=]{8,20}/g.test(str);
  }
};
