/**
 * @desc 存放当前系统用到的所有 localStorage 的key
 */

/*
  Login 登录
  用途：缓存获取验证码倒计时时间，防止页面刷新就能在此获取验证码
*/
export const LOGIN_COUNT_TIME = 'LOGIN_COUNT_TIME';

/*
  Login 登录
  用途：token
*/
export const TOKEN = 'token';
export const H5_TOKEN = 'h5-token';
export const KT_TOKEN = 'kt-token';
export const KT_LOT_CODE = 'lotId';

/*
  用途：避免代理登录导致数据保存串场（当前用户所在车场）
*/
export const KT_LOT_ID = 'KT_LOT_ID';
export const KT_USER_ID = 'KT_USER_ID';

/*
  Login 登录
  用途：记录登录类型，如扫码登录、密码登录、手机验证码登录
*/
export const LOGIN_TYPE = 'LOGIN_TYPE';

/*
  Index 首页
  用途：语言切换
*/
export const UNITY_LOCALE = 'kos_locale';
export const ACC_LOCALE = 'yc-language';

/*
  Index 登录
  用途：PC_SESSION
*/
export const PC_SESSION = 'PC_SESSION';

/*
  Index 首页
  用途：个性化配置
*/
export const PERSONALIZATION_ID = 'personalizationId';

/*
  LotTestRun 车场试运行
  用途：记录发起线下流程按钮状态时间戳
*/
export const LotTestRunOfflineBtnTimeStr = 'LotTestRunOfflineBtnTimeStr';
/*
  全局
  用途：项目是否是处于代理登录标识
*/
export const PROGRAM_IS_PROXY = 'PROGRAM_IS_PROXY';

/*
  全局
  用途：kos 旧项目的实时车流，用户id。重新登录时，需要清除
*/
export const KOS_K_USER_ID = 'K_USER_ID';

/*
  全局
  用途：应用的版本标识：99统一平台 01吉联版
*/
export const APP_VERSION_TYPE = 'APP_VERSION_TYPE';

/*
  全局
  用途：登录页是否展示app下载二维码
*/
export const SHOW_APP_QRCODE = 'SHOW_APP_QRCODE';

/*
  全局
  用途：6x首页接口轮询，需要当前的页面地址
*/
export const CURRENT_FULL_URL = 'CURRENT_FULL_URL';

/*
  全局
  用途：个性化配置
*/
export const PERSONALIZATION_CONFIG = 'PERSONALIZATION_CONFIG';

/*
  全局
  用途：登录选择车场历史记录
*/
export const SELECT_LOT_HISTORY_LOGIN = 'SELECT_LOT_HISTORY_LOGIN';

/**
 * 全局
 * 用途：存储的用户账号
 */
export const LOGIN_ACCOUNT = 'LOGIN_ACCOUNT';

/**
 * 全局
 * 用途：token
 */
export const DEFAUTL_WEBSITE_ICON = require('../assets/img/layout/website.png');

/**
 * 全局
 * 用途：区分新老框架
 */
export const FRAMEWORK = 'FRAMEWORK';
