import request from '@kt/request';

// 获取当前用户基本信息
const getLoginUser = (data: any) =>
  request.post('group/api/login/getLoginUser', data);
// 退出登录
const loginOut = (data?: any) =>
  request.post('user-center/api/login/loginOut', data);

const setCurrentGroup = (data: any) =>
  request.get('group/api/login/setCurrentGroup', data);
const getAllGroupAndTreeListOld = (data: any) =>
  request.post('group/api/group/lot/getAllGroupAndTreeList', data);
const getAllGroupAndTreeList = (data: any) =>
  request.post('group/api/group/lot/searchAllGroupAndTreeList', data);
// 查询车场饱和度
const searchSaturationInfo = (data: any) =>
  request.post('group/api/forward/saturation/searchSaturationInfo', data);
// 车场饱和度列表
const searchSaturationList = (data: any) =>
  request.post('/group/api/forward/saturation/searchSaturationList', data);

// 改造的菜单列表
const getMenuListByGroup = (data?: any) =>
  request.get('user-center/mgt/group/getBaseTreeByGroupId', data);

// 获取按钮权限
const getPermissionListByRoleIdList = () =>
  request.get('/group/api/permission/getPermissionListByRoleIdList');

// 获取组织结构
const getUserGroupList = (data?: any) =>
  request.get('group/api/group/user/getUserGroupList', data);

// 获取当前用户信息
// const getUserInfo = (data?: any) =>
//   request.post('group/api/login/getLoginUser', data);
const getUserInfo = (data?: any) =>
  request.get('user-center/mgt/user/getLoginUser');

//获取当前用户的集团列表
const listTopGroupByUserId = (data?: any) =>
  request.get('user-center/mgt/group/listTopGroupByUserId');

// 获取用户定制化信息
const getSetting = (data?: any) =>
  request.post('group/api/groupPlatform/getSetting', data);

// 换取云助手的token
const getNewToken = (lotId: any, urlType?: number) =>
  request.get(
    `user-center/mgt/user/toUnityPlatformNew?lotId=${lotId}&urlType=${urlType}`
  );

// 获取当前用户是否需要弹框
const getIsNeedBounced = () =>
  request.get('/group/api/frontend/bonce/isNeedBounced', {
    moduleId: 'parking-business'
  });

// 获取当前用户是否有该菜单权限
const getMenuAuth = (pathName: string) =>
  request.get('/group/api/frontend/bonce/getMenuAuth', {
    pathName
  });
export {
  getLoginUser,
  loginOut,
  getUserGroupList,
  setCurrentGroup,
  getAllGroupAndTreeList,
  getMenuListByGroup,
  getPermissionListByRoleIdList,
  searchSaturationInfo,
  searchSaturationList,
  getUserInfo,
  getSetting,
  getNewToken,
  getIsNeedBounced,
  getMenuAuth,
  listTopGroupByUserId
};
