import { Router } from 'vue-router';
import { useTagsStore } from '@/stores/tags';
import { useGlobalStore, useMenuStore } from '@/stores';
import { message } from 'ant-design-vue';
import router from '@/router';
import { h } from 'vue';
import { isIframeView } from '@/utils';
import i18n from '@/locale/index';

const { t, t: $t } = i18n.global;
interface MicroAppDispatchData {
  detail: {
    data: { type: string; payload: any };
  };
}
enum DispatchType {
  'PUSH_STATE', // 跨应⽤跳转，调⽤⽗应⽤⽅法，让⽗应⽤来做跳转
  'CHILD_SKIP', // 基座交给⼦应⽤，让⼦应⽤控制路由跳转
  'SELECT',
  'PUBLISH_MESSAGE',
  'DRAWER_CHANGE'
}
export default (mData: MicroAppDispatchData, router?: Router) => {
  const tagsStore = useTagsStore();
  const {
    detail: {
      data: { type, payload }
    }
  } = mData;
  console.log(
    `%c ---接收到子项目数据---：${JSON.stringify(payload)}`,
    'color: red;'
  );
  if (type === DispatchType[0]) {
    const { name, path, title, refresh, fromPathName, iframePath } = payload;
    const globalStore = useGlobalStore();
    console.log(payload);
    console.log(useMenuStore().transformMenuList);
    const cacheMenuItem =
      useMenuStore().transformMenuList.find((f: any) => f.pathName === name) ||
      globalStore.orgInfo;
    // 找不到跳转页提示无权限
    if (!cacheMenuItem) {
      message.error($t('暂无目标页面权限，请联系车场管理员申请'));
      return;
    }
    // 去掉该菜单的keepalive
    if (iframePath || isIframeView(path)) {
      globalStore.setOrgInfo({
        ...cacheMenuItem,
        isIframeView: true,
        timestamp: +new Date(),
        fromPathName,
        iframePath
      });
      router?.push(iframePath || path);
      // 组织树设为展开状态
      useGlobalStore().setOrgPanelStatus(true);
    } else {
      const arr = path.split('?');
      if (cacheMenuItem.displayType === 0) {
        // 无组织
        // 将组织树信息清空
        globalStore.setOrgInfo({
          ...cacheMenuItem,
          displayType: 0,
          refresh,
          queryPath: arr?.[0],
          queryStr: arr?.[1] ?? '',
          treeData: [],
          defaultSelectNode: {},
          fullList: [],
          timestamp: +new Date(),
          fromPathName
        });
      } else {
        globalStore.setOrgInfo({
          ...cacheMenuItem,
          queryPath: arr?.[0],
          queryStr: arr?.[1] ?? '',
          refresh,
          timestamp: +new Date(),
          fromPathName
        });
      }
    }
    tagsStore.addTag({
      name: title,
      route: { name: name, path: path || iframePath },
      selected: true,
      closable: true
    });
  } else if (type === DispatchType[4]) {
    useGlobalStore().setChildDrawer(payload.pathName, payload.status);
  }
};
