/**
 * @author MKF
 * @desc 注册地图
 * @date 2023/10/26
 */
import AMapLoader from '@amap/amap-jsapi-loader';

export const amapRegister = async () => {
  try {
    AMapLoader.load({
      key: 'd8b9a5867db4f20ea16bbd8f6f289098',
      version: '2.0',
      plugins: [
        'AMap.Marker',
        'AMap.InfoWindow',
        'AMap.AutoComplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Geolocation',
        'AMap.Geocoder',
        'AMap.Polyline'
      ]
    }).then(AMap => {
      // @ts-ignore
      window!.AMap = AMap;
    });
  } catch (error) {
    console.log('地图加载出错，重试', error);
    AMapLoader.load({
      key: 'd8b9a5867db4f20ea16bbd8f6f289098',
      version: '2.0',
      plugins: [
        'AMap.Marker',
        'AMap.InfoWindow',
        'AMap.AutoComplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Geolocation',
        'AMap.Geocoder',
        'AMap.Polyline'
      ]
    }).then(res => {
      // @ts-ignore
      window!.AMap = res;
    });
  }
};
