/**
 * @author
 * @desc 登录/顶部切换组织，默认跳转页面
 *       如果有首页权限跳转至首页，没有则跳转到第一个菜单
 * @date 2022-08-11
 */
import { useMenuStore } from '@/stores';
import { ref } from 'vue';
import { bigScreenList } from '@/router';
import { registerBigScreenMenu } from '@/utils/menu';

export const useToPage = () => {
  const menuStore = useMenuStore();
  const isHomePage = ref<boolean>(false);
  const isFindcarHomePage = ref<boolean>(false);
  const url = ref<string>('/404');

  // 找到第一个菜单的path
  const findPath = (data: any) => {
    if (!data?.length) return '';
    let path = '';
    if (data[0]?.childrenMenuList?.length) {
      path = findPath(data[0]?.childrenMenuList);
    } else {
      path = data[0]?.pcPath;
    }
    return path;
  };
  const jumpPath = () => {
    // 判断是否拥有首页权限
    if (menuStore.transformMenuList.length) {
      // 这里判断一下是集团首页寻车首页是否存在
      menuStore.transformMenuList.forEach((item: any) => {
        if (item.pathName === 'HomePage') {
          isHomePage.value = true;
        }
        if (item.pathName === 'page') {
          isFindcarHomePage.value = true;
        }
      });
      if (isHomePage.value && !isFindcarHomePage.value) {
        url.value = '/group-parking/homePage';
      } else if (!isHomePage.value && isFindcarHomePage.value) {
        url.value = '/fcfront/page';
      } else if (isHomePage.value && isFindcarHomePage.value) {
        url.value = '/fcfront/page';
      }
    }

    // 无首页权限默认显示第一个菜单
    if (!isHomePage.value && menuStore.treeMenuList.length) {
      url.value = findPath(menuStore.treeMenuList);
    }

    //判断是否是大屏跳转登录
    const loginRedirectUrl = sessionStorage.getItem('loginRedirectUrl') || '';
    if (loginRedirectUrl) {
      // 永策大屏路由注册
      const obj = bigScreenList.find((item: any) =>
        item.url.includes(loginRedirectUrl)
      );
      if (obj?.url) {
        registerBigScreenMenu([obj]);
      }
      url.value = loginRedirectUrl;
    }

    return url.value;
  };

  return jumpPath;
};
