/**
 * @author DEJAVU
 * @desc 处理菜单，tag切换跳转
 * @date 2022-05-21 14:54:17
 */

import router from '@/router';
import { useGlobalStore } from '@/stores';
import { isIframeView } from '@/utils';

const useHandleRouteChange = (menuItem: any) => {
  const globalStore = useGlobalStore();
  if (isIframeView(menuItem.pcUrl)) {
    globalStore.setOrgInfo({
      ...menuItem,
      isIframeView: true,
      timestamp: +new Date()
    });
    router.push(menuItem.pcPath);
    // 进入老页面时，重置新页面的组织树收缩状态
    useGlobalStore().resetChildDrawer();
    return;
  }
  //@ts-ignore
  if (menuItem.displayType === 0) {
    // 无组织
    // 将组织树信息清空
    globalStore.setOrgInfo({
      ...menuItem,
      displayType: 0,
      treeData: [],
      defaultSelectNode: {},
      fullList: [],
      timestamp: +new Date()
    });
  } else {
    globalStore.setOrgInfo({
      ...menuItem,
      timestamp: +new Date()
    });
  }
};
export default useHandleRouteChange;
