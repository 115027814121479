import request from '@kt/request';

// const login = (data: any) => request.post('group/api/login/login', data); // 1.0登录
// loginMethod 1：手机密码登录（默认） 2：手机验证码登录 3：邮箱密码登录 4：邮箱验证码登录
const login = (data: any) => request.post('user-center/api/login/login', data); // 永策pro2.0
const getLoginUser = (data: any) =>
  request.post('group/api/login/getLoginUser', data);

// 2023712
// const setCurrentGroup = (data: any) =>
// request.get('group/api/login/setCurrentGroup', data);

// const getImgCode = () => request.get('group/api/login/getImgCode');
const getImgCode = () => request.get('user-center/api/login/getImgCode');

// const checkImgCode = (data: any) =>
//   request.post('group/api/login/checkImgCode', data);
const checkImgCode = (data: any) =>
  request.post('user-center/api/login/checkImgCode', data); // pro2.0

const checkLoginPwd = (data: any) =>
  request.post('group/api/login/checkLoginPwd', data);

// 修改密码
const updatePwd = (data: any) =>
  request.post('user-center/mgt/user/updatePwd', data);

const openGetSetting = (data: any) =>
  request.post('group/api/groupPlatform/openGetSetting', data);
const getToken = (data: any) =>
  request.get(`group/api/frontend/menu/getToken?groupId=${data}`);

// // 获取手机验证码
// const sendMessage = (phone: string) =>
//   request.get('group/api/login/sendMessage', { phone });
// 获取手机验证码
const sendMessage = (data: { phone: string; groupId: string }) =>
  request.get('user-center/api/login/sendMessage', data); // pro2.0

// 通过手机号修改密码
const updatePwdByPhone = (data: {
  phone: string;
  newPwd: string;
  groupId: string;
  code: string;
}) =>
  request.post('user-center/api/login/updatePwdByPhone', {
    ...data
  });

// 校验手机号
const checkPhone = ({ phone, code, topGroupId }: any) =>
  request.get('user-center/api/login/checkPhone', {
    phone,
    code,
    topGroupId
  });

// 代理登录
const proxyLogin = (data: { proxyCode: string; topGroupId: string }) =>
  request.post('user-center/api/login/proxyLogin', data);

export default class LoginAPI {
  // 根据域名获取当前登录的集团信息
  static getGroupInfoByDomain(domain: string) {
    return request.get('user-center/api/login/getGroupInfoByDomain', {
      domain
    });
  }

  // PC端-企业微信登录
  static pcWeComLogin(data: {
    code: string;
    corpId: string;
    groupId: string;
    frontEnv: number; // 前端环境：0，web；1，app
  }) {
    return request.post('user-center/api/login/pcWeComLogin', {
      ...data
    });
  }

  // 发送邮箱验证码
  static sendEmailMessage(data: { email: string; groupId: string }) {
    return request.get('user-center/api/login/sendEmailMessage', {
      ...data
    });
  }
}

export {
  login,
  getLoginUser,
  // setCurrentGroup,
  getImgCode,
  checkImgCode,
  checkLoginPwd,
  updatePwd,
  openGetSetting,
  getToken,
  sendMessage,
  updatePwdByPhone,
  checkPhone,
  proxyLogin
};
