import Cookies from 'js-cookie';

export const bifurcateBy = (
  arr: any[],
  fn: (val: any, index: number) => unknown
) =>
  arr.reduce(
    (acc, val, i) => (acc[fn(val, i) ? 0 : 1].push(val), acc),
    [[], []]
  );

/**@desc 获取当前语言
 * */
export const getLanguage = (): string => {
  return (Cookies.get('yc-language') as string) || 'zh-CN';
};
