import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './locale';
import { createPinia } from 'pinia';

import '@/assets/css/tailwind.css';

import microApp from '@micro-zoe/micro-app';
// @ts-ignore
import Empty from '@kt/unity-antd-empty';
import Form from '@kt/unity-antd-form';
import Modal from '@kt/unity-antd-modal';
//@ts-ignore
import directives from '@/directives';
import {
  ConfigProvider,
  Button,
  Spin,
  Radio,
  Pagination,
  Calendar,
  DatePicker,
  Layout,
  Menu,
  Alert,
  Card,
  Select,
  Row,
  Col,
  Tag,
  Checkbox,
  Dropdown,
  Tooltip,
  Popover,
  InputNumber,
  Badge,
  Tree,
  Input,
  Drawer,
  Table,
  Transfer,
  Divider
} from 'ant-design-vue';
import {
  CaretDownOutlined,
  FolderOutlined,
  FileOutlined,
  SearchOutlined,
  PlusOutlined,
  UserOutlined,
  PoweroffOutlined,
  DownOutlined,
  ReloadOutlined,
  RollbackOutlined,
  EditOutlined,
  LockOutlined,
  DeleteOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  BarcodeOutlined,
  TableOutlined,
  SettingOutlined,
  SmileOutlined,
  LaptopOutlined,
  NotificationOutlined,
  CloseCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  EllipsisOutlined
} from '@ant-design/icons-vue';
import '@/assets/js/iconfont.js';
import '@/assets/js/mobleIconfont.js';
import requestRegister from './requestRegister';
import { amapRegister } from '@/utils/amapRegister';

const app = createApp(App);
// #region 注册全局插件
const plugins: any = {
  router,
  pinia: createPinia(),
  i18n,
  ConfigProvider,
  Button,
  Spin,
  Form,
  Empty,
  Input,
  Radio,
  Pagination,
  Calendar,
  DatePicker,
  Layout,
  Menu,
  Alert,
  Card,
  Select,
  Row,
  Col,
  Tag,
  Checkbox,
  Dropdown,
  Tooltip,
  Popover,
  InputNumber,
  Badge,
  Tree,
  Drawer,
  Table,
  Transfer,
  directives,
  Divider
};
// #endregion

// #region 注册全局antd icon
const globalAntdIcons: any[] = [
  CaretDownOutlined,
  FolderOutlined,
  FileOutlined,
  SearchOutlined,
  PlusOutlined,
  UserOutlined,
  PoweroffOutlined,
  DownOutlined,
  ReloadOutlined,
  RollbackOutlined,
  EditOutlined,
  LockOutlined,
  DeleteOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  BarcodeOutlined,
  TableOutlined,
  SettingOutlined,
  SmileOutlined,
  LaptopOutlined,
  NotificationOutlined,
  CloseCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  EllipsisOutlined
];
// #endregion

Object.keys(plugins).forEach(key => {
  app.use(plugins[key]);
});
// 注册 Modal

app.use(Modal as any, {
  props: {
    // 是否显示右上角的关闭按钮
    closable: true,
    // 是否支持键盘 esc 关闭
    keyboard: false,
    // 点击蒙层是否允许关闭
    maskClosable: false
  }
});
globalAntdIcons.forEach((comp: any) => {
  app.component(comp.displayName, comp);
});

requestRegister(app);

amapRegister();

app.mount('#app');

microApp.start({ disableScopecss: true });
