/**
 * @author
 * @desc 点击组织树刷新子应用（模拟重新渲染）
 * @issues https://github.com/micro-zoe/micro-app/issues/268
 * @date 2022-08-04
 */

import { ref, watch } from 'vue';
import { useGlobalStore } from '@/stores';

export const useRenderChild = () => {
  const globalStore = useGlobalStore();
  const flag = ref<boolean>(true);

  watch(
    () => globalStore.childDefaultInfo,
    val => {
      flag.value = false;
      setTimeout(() => {
        flag.value = true;
      }, 0);
    },
    {
      deep: true
    }
  );

  return flag;
};
