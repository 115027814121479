import { defineStore } from 'pinia';
export const useLotTestRunStore = defineStore('lotTestRun', {
  state: () => ({
    countSeconds: 15, // 限制模式倒计时秒数
    lotTestRunInfo: {} as any
  }),
  getters: {
    // acceptStatus状态值： 1.试运行中，未点击使用正常，2.试运行中，已点击使用正常，3.超时未申请验收 ，4.已验收通过 ，5，试用期延期

    // 车场处于试运行期间（不包括限制期间）
    isInTestRunTime: state => {
      const { acceptStatus } = state.lotTestRunInfo;
      return acceptStatus && acceptStatus !== 3 && acceptStatus !== 4;
    },
    // 是否开启限制模式
    isLimitMode: state => {
      const { acceptStatus } = state.lotTestRunInfo;
      return acceptStatus === 3;
    },
    // 试运行倒计时,剩余天数
    countForTestRunTime: state => {
      const { restRunDays } = state.lotTestRunInfo;
      return restRunDays;
    },
    // 当前是否为正常的车场
    isNormalLot: state => {
      const { acceptStatus } = state.lotTestRunInfo;
      return !acceptStatus || acceptStatus === 4;
    },
    // 试运行 id
    acceptId: state => {
      const { acceptId } = state.lotTestRunInfo;
      return acceptId;
    }
  },
  actions: {
    // 设置试用期状态
    setLotTestRunStatus() {
      this.lotTestRunInfo.acceptStatus = status;
    },
    setLotTestRunInfo(data: any) {
      this.lotTestRunInfo = data;
    },
    resetLotTestRunInfo() {
      this.lotTestRunInfo = {};
    },
    async getLotTestRunInfo() {
      // const res = await getAcceptInfo();
      // const { code, data } = res;
      // if (code === 200) {
      //   // data.acceptStatus = 3;
      //   this.setLotTestRunInfo(data || {});
      // }
      // return res;
    }
  }
});
