import { defineStore } from 'pinia';
import { getIsNeedBounced } from '@/api/global';
interface ISettingInfo {
  loginBackgroundImg: string;
  loginWelcomeMsg: string;
  platformLogo: string;
  platformName: string;
  websiteIcon: string;
}
export const useGlobalStore = defineStore('global', {
  state: () => ({
    lotType: null,
    mainLoading: false,
    settingInfo: {} as ISettingInfo, // 个性化设置信息
    appFromLogin: false, // 是走登陆流程进入项目的标识
    orgInfo: {}, // 组织树信息
    cacheRoutes: [] as string[], // 缓存动态路由，防止二次添加
    orgPanleStatus: false,
    childDrawer: {} as any,
    panelId: null,
    iframeInfo: null,
    orgPanelStatus: true,
    // 记录点击过的组织节点
    clicked_menuId_map: {} as any,
    // 第一次访问子应用带入的数据
    childDefaultInfo: {},
    // 是否需要弹出宣传弹窗
    isShowPropagandaModal: false,
    // 需要跳转的页面
    toPathName: ''
  }),
  getters: {},
  actions: {
    setIframeInfo(info: any) {
      this.iframeInfo = info;
    },
    setChildDefaultInfo(info: any) {
      this.childDefaultInfo = info;
    },
    setClickedMenuIdMap(pathName: string, node: any) {
      //@ts-ignore
      this.clicked_menuId_map[pathName] = node;
    },
    setOrgPanelStatus(status: boolean) {
      this.orgPanelStatus = status;
    },
    // 保存新页面组织树收缩状态
    setChildDrawer(pathName: string, status: boolean) {
      this.childDrawer[pathName] = status;
    },
    resetChildDrawer() {
      this.childDrawer = {};
    },
    setOrgCollapse(status: boolean) {
      this.orgPanleStatus = status;
    },
    setSettingInfo(obj: any) {
      this.settingInfo = obj;
    },
    setMainLoading(status: boolean) {
      this.mainLoading = status;
    },
    setAppFromLogin(status: boolean) {
      this.appFromLogin = status;
    },
    resetGlobal() {
      this.mainLoading = false;
      this.appFromLogin = false;
      this.settingInfo = {} as ISettingInfo;
      this.lotType = null;
      this.cacheRoutes = [];
      this.panelId = null;
      this.iframeInfo = null;
      this.orgPanelStatus = true;
      this.clicked_menuId_map = {};
      this.childDefaultInfo = {};
    },
    setOrgInfo(orgInfo: any) {
      this.orgInfo = orgInfo;
    },
    setCacheRoutes(name: string) {
      this.cacheRoutes.push(name);
    },
    // 点击组织树，设置组织或车场id
    setPanelId(id: any) {
      this.panelId = id;
    },
    setPropagandaModal() {
      this.isShowPropagandaModal = false;
    },
    // 设置要跳转的地址
    setToPathName(param: string) {
      this.toPathName = param;
    }
  }
});
