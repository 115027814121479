import { defineStore } from 'pinia';
import {
  getUserInfo,
  listTopGroupByUserId,
  loginOut
  // setCurrentGroup
} from '@/api/global';
import { useGlobalStore } from './global';
import { ACC_LOCALE, TOKEN, UNITY_LOCALE } from '@/utils/localKey';
import Cookies from 'js-cookie';
import router from '../router';
import { useMenuStore } from './menu';
import { unmountAllApps } from '@micro-zoe/micro-app';
import { getPrimaryDomain, clearCookies } from '@/utils';
import { useTagsStore } from './tags';
import i18n from '@/locale';
import { languageMap } from 'shared/utils/commonMap';

interface IUserProps {
  groupId: string;
  groupName: string;
  token: string;
  roleList: RoleList[];
  userId: string;
  userName: string;
  [key: string]: string | null | RoleList[];
}

interface RoleList {
  roleId: string;
  roleName: string;
  groupId: string;
  tempId: string;
  remarks: string;
  createdTime: string;
}

export interface IGroupProps {
  groupId: string;
  groupName: string;
}

interface GroupInfoType {
  groupId: string;
  groupName: string;
  weComAgentId: string;
  weComCorpId: string;
  loginConfigList: Array<{ loginMethod: number; loginType: number }>;
  defaultLanguage: string;
}

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {} as IUserProps, // 用户信息
    groupList: [] as IGroupProps[], // 用户关联的组织列表
    noGroupFlag: false, // 标识当前用户没有关联的组织列表
    tempToken: '', // 临时存储token，仅登录之后获取用户信息时使用
    groupInfo: {} as GroupInfoType // 集团信息
  }),
  actions: {
    setTempToken(param: string) {
      this.tempToken = param;
    },
    setToken() {
      Cookies.set(TOKEN, this.tempToken);
      localStorage.setItem(TOKEN, this.tempToken);
      this.tempToken = '';
    },
    setUserInfo(info: IUserProps) {
      this.userInfo = info;
      window.localStorage.setItem('userInfo', JSON.stringify(info));
    },
    setGroupInfo(info: GroupInfoType) {
      this.groupInfo = info || {};

      // 保存默认登录方式
      const obj: any = {
        0: 'phone',
        1: 'email',
        2: 'wework'
      };
      (info.loginConfigList || []).forEach(item => {
        if (item.loginType === 0) {
          localStorage.setItem('defaultLoginMethod', obj[item.loginMethod]);
        }
      });

      // 缓存当前默认语言
      const currentLanguage = Cookies.get(ACC_LOCALE);
      if (
        (!currentLanguage ||
          !languageMap.find(item => item.code === currentLanguage)) &&
        this.groupInfo.defaultLanguage
      ) {
        Cookies.set(ACC_LOCALE, this.groupInfo.defaultLanguage);
        i18n.global.locale.value = this.groupInfo.defaultLanguage;
      }
    },
    setGroupList(arr: IGroupProps[]) {
      this.groupList = arr;
    },
    setNoGroupFlag(status: boolean) {
      this.noGroupFlag = status;
    },
    resetUserInfo() {
      this.userInfo = {} as IUserProps;
    },
    async getUserInfo() {
      const { resultCode, data } = await getUserInfo();
      if (!data) return;
      data.groupId = data.topGroupId;
      data.groupName = data.topGroupName;
      console.log('data', data);

      if (resultCode === 200) {
        this.setUserInfo(data);
        // const globalStore = useGlobalStore();
        // globalStore.setSettingInfo(data.settingInfo);
        return data;
      }
    },
    async getUserGroupList() {
      const { resultCode, data } = await listTopGroupByUserId();
      if (resultCode === 200) {
        if (!data || !data.length) {
          this.setNoGroupFlag(true);
        }
        this.setGroupList(data);
      }
      return data ? data : [];
    },
    // 切换组织
    // async setCurrentGroup(parmas: any): Promise<{
    //   code: number;
    //   data: any;
    // }> {
    //   const data = await setCurrentGroup(parmas);
    //   return data;
    // },
    // 退出登录
    async logout() {
      const { code, resultMsg } = await loginOut({});

      if (code === 200) {
        this.cleanInfo(resultMsg);
      }
    },
    // 清楚信息 type: not-auth清除所有token
    cleanInfo(type?: string) {
      const menuStore = useMenuStore();
      const tagStore = useTagsStore();
      const global = useGlobalStore();
      const primaryDomain = getPrimaryDomain();
      const path =
        type === 'adfs'
          ? 'https://galaxy.capitaland.com/adfs/ls/?wa=wsignout1.0'
          : `/login?groupId=${this.userInfo.groupId}`;
      unmountAllApps();
      global.resetGlobal();
      tagStore.resetStatus();
      menuStore.resetMenuData();
      Cookies.remove(UNITY_LOCALE, { domain: primaryDomain });
      this.resetUserInfo();
      Cookies.remove(TOKEN);
      Cookies.remove(TOKEN, { domain: primaryDomain });

      clearCookies();

      localStorage.clear();

      const loginRedirectUrl = sessionStorage.getItem('loginRedirectUrl') || '';
      sessionStorage.clear();
      //兼容大屏跳转登录
      if (loginRedirectUrl?.includes('http')) {
        sessionStorage.setItem('loginRedirectUrl', loginRedirectUrl);
      }
      type === 'adfs' ? window.location.replace(path) : router.replace(path);
    }
  }
});
