import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router';
import { NotFound } from '@kt/unity-components';
import DefaultLayout from '@/layout/Default.vue';
import Cookies from 'js-cookie';
import { TOKEN, UNITY_LOCALE, DEFAUTL_WEBSITE_ICON } from '@/utils/localKey';
import { getPrimaryDomain, getBrowser, getCurrentToken } from '@/utils';
import {
  useUserStore,
  useMenuStore,
  UrlTarget,
  useGlobalStore
} from '@/stores';
import usePageInit from '@/hooks/usePageInit';
import { useToPage } from '@/hooks/useToPage';
import { getStoreMenuMap } from '@/utils/menu';
import { isWx, isDingding } from '@/utils/client';
import LoginAPI, { proxyLogin } from '@/api/login';
import { toRaw } from 'vue';
import gpNew from '@/pages/MicroApp/GroupParking.vue';
import i18n from '@/locale/index';

const { t, t: $t } = i18n.global;
const browser = getBrowser();
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/group-parking/homePage'
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import('@/pages/Login/index.vue')
  },
  {
    path: '/nonsupport',
    name: 'Nonsupport',
    meta: { title: '不支持' },
    component: () => import('@/pages/Nonsupport/Nonsupport.vue')
  },
  {
    path: '/login/step2',
    name: 'LoginStep2',
    meta: { title: '选择车场' },
    component: () => import('@/pages/Login/index.vue')
  },
  {
    path: '/userAuth',
    name: 'UserAuth',
    meta: { title: '授权' },
    component: () => import('@/pages/UserAuth/UserAuth.vue')
  },
  {
    path: '/messageTransit',
    name: 'MessageTransit',
    meta: { title: '永策平台' },
    component: () => import('@/pages/MessageTransit/MessageTransit.vue')
  },
  {
    path: '/',
    name: 'Page404',
    component: DefaultLayout,
    children: [
      {
        path: '/404',
        name: 'Page404',
        component: NotFound,
        meta: {
          title: '404'
        }
      }
    ]
  },
  {
    path: '/',
    name: 'Refresh',
    component: DefaultLayout,
    children: [
      {
        path: '/refresh',
        name: 'Refresh',
        component: () => import('@/pages/refresh.vue'),
        meta: {
          title: '404'
        }
      }
    ]
  },
  // 工具页面（与平台业务无关，避免单独部署）
  {
    path: '/syncHistoryTask',
    name: 'SyncHistoryTask',
    meta: { title: '同步历史任务' },
    component: () => import('@/pages/SyncHistoryTask/SyncHistoryTask.vue')
  },
  {
    path: '/demoReport',
    name: 'DemoReport',
    meta: { title: '同步历史任务' },
    component: () => import('@/pages/DemoReport/DemoReport.vue')
  },
  {
    path: '/complement',
    name: 'Complement',
    meta: { title: '补数工具' },
    component: () => import('@/pages/Complement/Complement.vue')
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
// 与平台无关的页面，无需请求平台的接口
const singleList = ['/syncHistoryTask', '/demoReport', '/complement'];
const whiteList = [
  '/login',
  '/parkManage',
  '/roleManage',
  '/iePage',
  '/test',
  '/userManage',
  '/noticeManage',
  '/menuManage',
  '/groupManage'
];

// 判断是否有token和locale，这段逻辑不太明白
const isHasTokenOrLocale = (to: any) => {
  const primaryDomain = getPrimaryDomain();
  // 链接上有token则存入缓存中。（主要用在速停车后台代理操作）
  const tk = to.query['token'];
  const locale = to.query[UNITY_LOCALE];
  tk && Cookies.set(TOKEN, tk);
  locale &&
    Cookies.set(UNITY_LOCALE, locale, { expires: 365, domain: primaryDomain });
};
// 设置网站顶部icon
const setWebSiteIcon = () => {
  const globalStore = useGlobalStore();
  const websiteIcon =
    globalStore.settingInfo?.websiteIcon || DEFAUTL_WEBSITE_ICON;
  const favicon = document.querySelector('link[rel="icon"]');
  // //@ts-ignore
  // favicon.style.width = '28px';
  // //@ts-ignore
  // favicon.style.height = '18px';
  //@ts-ignore
  favicon && (favicon.href = websiteIcon);
};

// 注册动态路由
const registerMenu = (to: any, next: any) => {
  const menuStore = useMenuStore();
  const list = toRaw(menuStore.treeMenuList);
  const menuMap = getStoreMenuMap(list as UrlTarget[]);
  menuStore.setStoreMenuMap(menuMap);
  const addRoutes = () => {
    menuStore.iframeBaseRoute.forEach(route => {
      const record = {
        path: '/',
        component: DefaultLayout,
        children: [route]
      };
      router.addRoute(record);
    });
    router.addRoute({
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    });
  };
  // addRoutes();
  //@ts-ignore
  // console.log('动态路由', router.getRoutes());
  // console.log(
  //   '动态路由ui一体化',
  //   router.getRoutes().filter(f => f.path.includes('group-parking'))
  // );
  // 异步路由需要这样写法，否则动态路由页面刷新会出现白屏
  next({ ...to, replace: true });
};

// 判断是否支持当前浏览器
const isNonsupportBrowser = (next: any) => {
  const agent = navigator.userAgent;
  let result = false;
  const obj: any = {
    Mozilla: null,
    AppleWebKit: null,
    Chrome: null,
    Safari: null,
    Firefox: null
  };
  agent.split(' ').forEach((item: any) => {
    for (const key in obj) {
      if (item.includes(key))
        obj[key] = Number(item.replace(`${key}/`, '')?.split('.')[0] || 0);
    }
  });
  if (obj.Chrome && obj.Chrome <= 90) result = true;
  return result;
};

// saas代理登录
const saasProxyLogin = async (to: any) => {
  const { proxyCode, topGroupId } = to.query;
  if (proxyCode) {
    const { data } = await proxyLogin({ proxyCode, topGroupId });
    if (data?.token) {
      Cookies.set(TOKEN, data.token);
      localStorage.setItem(TOKEN, data?.token);
    }
  }
};

// 根据域名获取集团信息
const checkGroupInfo = async () => {
  const userStore = useUserStore();
  if (!userStore.groupInfo?.groupId) {
    const { data } = await LoginAPI.getGroupInfoByDomain(
      process.env.NODE_ENV === 'production'
        ? window.location.origin
        : 'http://yongce-pro-test.keytop.cn'
    );
    if (data?.groupId) {
      userStore.setGroupInfo(data);
    }
  }
};

// 直接访问大屏链接无token时跳至登录页，登陆后直接到大屏页面
export const bigScreenList = [
  {
    url: '/group-parking/businessScreen',
    title: $t('业务大屏')
  }
];
const checkFormBigScreen = (to: RouteLocationNormalized) => {
  const flag = bigScreenList.some((item: any) => item.url.includes(to.path));
  if (flag) {
    sessionStorage.setItem('loginRedirectUrl', to.path);
  }
};

router.beforeEach(async (to, from, next) => {
  if (singleList.includes(to.path)) {
    next();
    return;
  }

  if (to.query?.proxyCode) {
    await saasProxyLogin(to);
    next(to.path);
    return;
  }

  await checkGroupInfo();
  isHasTokenOrLocale(to);
  const isLogin = getCurrentToken();
  setWebSiteIcon();
  // 判断是否登录
  if (isLogin) {
    const userStore = useUserStore();
    const { userInfo, noGroupFlag, groupList } = userStore;
    const menuStore = useMenuStore();
    const globalStore = useGlobalStore();
    const { noMenuFlag, treeMenuList } = menuStore;
    const { userId } = userInfo;
    if (
      !userId ||
      (!treeMenuList.length && !noMenuFlag) ||
      (!groupList.length && !noGroupFlag)
    ) {
      const { init } = usePageInit(true);
      const resArr = await init();
      // 可能存在拿不到
      if (
        (resArr.every(item => Boolean(item)) &&
          resArr.some(item => item.code === 4400)) ||
        !resArr.length ||
        resArr.some(item => item === undefined)
      ) {
        userStore.setNoGroupFlag(true);
        menuStore.setNoMenuFlag(true);
        next();
        return;
      }
      registerMenu(to, next);
    } else if (globalStore.appFromLogin) {
      globalStore.setAppFromLogin(false);
      registerMenu(to, next);
    }
    if (['/login'].includes(to.path)) {
      const jumpPath = useToPage();
      const jumpUrl = jumpPath();
      if (jumpUrl.includes('http')) {
        next();
      }
      next(jumpPath());
    }
    next();
  } else {
    checkFormBigScreen(to);
    //#region 企业微信或者钉钉用户身份验证缓存判断拦截
    if (isWx || isDingding) {
      if (to.name !== 'UserAuth') {
        const redirectUrl = encodeURIComponent(window.location.href);
        next({
          path: '/userAuth',
          query: { redirectUrl }
        });
        return;
      }
      next();
      return;
    }
    //#endregion
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next('/login');
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  const globalStore = useGlobalStore();
  globalStore.setMainLoading(false);
  // document.querySelector('#global-loading').style.display = 'none'; // 关闭项目入口loading
});
router.beforeResolve((to, from, next) => {
  document.title = localStorage.getItem('websiteTitle') || $t('永策平台');
  // i18n.global.t(`router.${to.name as string}`) || to.meta.title || '';
  next();
});

export default router;
