/**
 * @author DEJAVU
 * @desc 向子应用发送消息
 * @date 2022-04-18 17:25:10
 */
import microApp from '@micro-zoe/micro-app';
const publishMessage = (appName: string, info: any) => {
  microApp.setData(appName, { type: 'PUBLISH_MESSAGE', payload: info });
};

export default publishMessage;
