import i18n from '@/locale';
import request, { RequestConfig } from '@kt/request';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { App } from 'vue';
import { message } from 'ant-design-vue';
import { Loading } from '@kt/unity-components';
import { useUserStore } from '@/stores';
import { getCurrentToken } from '@/utils';
import { getLanguage } from 'shared/utils';
import { isWx } from '@/utils/client';
import Cookies from 'js-cookie';
const { t, t: $t } = i18n.global;

const customCode: any[] = [
  {
    strategy: 'BUSINESS_ERROR',
    codeKey: 'code',
    // 0 正确，1表示错误
    codeValue: 510,
    msgKey: 'message'
  },
  {
    strategy: 'NOT_AUTH',
    codeKey: 'code',
    codeValue: 401,
    msgKey: 'message'
  },
  {
    strategy: 'PERMISSION_DENIED',
    codeKey: 'code',
    codeValue: 510,
    msgKey: 'message'
  },
  {
    strategy: 'SYSTEM_ERROR',
    codeKey: 'code',
    codeValue: 4001,
    msgKey: 'message'
  }
];
const config: RequestConfig = {
  nodeEnv: process.env.NODE_ENV,
  customEnv: process.env.MY_ENV,
  printLog: false,
  showModal(message) {},
  showToast(_message) {
    message.destroy();
    message.error(_message);
  },
  showLoading: {
    show() {
      Loading.show();
    },
    hide() {
      Loading.hide();
    }
  },
  strategies: {
    //业务逻辑异常
    BUSINESS_ERROR({
      branch,
      data,
      config,
      codeKey,
      codeValue,
      msgKey,
      showToast
    }) {
      const message = data[msgKey] || $t('系统异常，请稍后再试');
      if (data[codeKey] === codeValue) {
        config.codeErrTip && showToast(message);
      }
    },
    //没有授权登录
    NOT_AUTH({ data, config, codeKey, codeValue, showToast }) {
      if (!config.customAuth) return;
      const messageText = $t('用户未登录，请先登录');
      if (data[codeKey] === codeValue) {
        if (!isWx) showToast(messageText);
        useUserStore().cleanInfo('not-auth');
        // debugger;
        // if (isWx) {
        //   window.location.reload();
        // } else {
        //   window.location.replace(`${window.location.origin}/unityp/login`);
        // }
      }
    },
    // 权限不足
    PERMISSION_DENIED({ data, codeKey, codeValue, showModal }) {
      const message = $t('权限不足，请您联系管理员');
      if (data[codeKey] === codeValue) {
        showModal(message);
      }
    },
    // 系统异常
    SYSTEM_ERROR({ data, codeKey, codeValue, showModal }) {
      const message = $t('系统异常，请稍后再试');
      if (data[codeKey] === codeValue) {
        showModal(message);
      }
    }
  },
  // 需要配置 isProxy 选项来判断是否需要使用 node 代理
  branches: {
    master: {
      origin: {
        test: window.origin,
        prod: window.origin
      },
      validator: customCode,
      // rewrite or add
      requestExtend: {
        /**
         * 重写 get 方法，为了告诉 TypeScript 这些新 property，我们可以使用[模块扩充](https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation)。
         *
         * request.get2() 发起请求时，根据环境修改请求 url 的上下文
         */
        get2(path, data, config) {
          const context =
            process.env.NODE_ENV === 'development' ? 'test/' : 'prod/';
          return {
            ...config,
            method: 'GET',
            // 添加 url 前缀
            url: `${context}${path}`,
            params: data
          };
        },
        /**
         * 上传文件方法
         */
        upload(path, data, config) {
          const formData: FormData = new FormData();
          for (const key in data) {
            formData.append(key, data[key]);
          }
          data = formData;
          return {
            ...config,
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            method: 'POST',
            url: path,
            data
          };
        }
      }
    },
    groupAPI: {
      origin: {
        test: window.origin + '/cloud',
        prod: window.origin + '/cloud'
      },
      validator: customCode
    },
    biopenAPI: {
      origin: {
        test: 'https://biopen.keytop.cn',
        prod: 'https://biopen.keytop.cn'
      },
      validator: customCode
    },
    messageAPI: {
      origin: {
        test: window.origin,
        prod: 'http://192.168.25.32:27804'
      },
      validator: customCode
    }
  }
};
const requestRegister = (app: App) => {
  app.use(request, config);

  // #region 实例拦截器配置
  const sharedInterceptorsRequest = (config: any) => {
    config.headers!['token'] = getCurrentToken()!;
    config.headers!['accept-language'] = getLanguage();
    const userStore = useUserStore();
    config.headers!['groupid'] = userStore.userInfo.groupId || '';
    return config;
  };
  const sharedInterceptorsResponse = (response: any) => {
    const { data, status } = response;
    if (data && status === 200) {
      // 兼容 接口code 和 message 不统一 ---start
      const { code, resultCode, message, resultMsg, data: dataObj } = data;
      const codeMapUnity: any = {
        3000: 401, // 未登录
        2000: 200, // 成功
        5000: 510,
        5001: 501,
        5002: 502,
        5003: 503
      };
      data.data =
        dataObj ||
        (dataObj === null || dataObj === 0 || dataObj === false ? dataObj : []); // 有的接口数据为空时，没有data字段，防止报错，做此兼容
      data.code = resultCode || code || codeMapUnity[code] || 4401;
      data.message = resultMsg || message;
      response.data = data;
    }
    return response;
  };
  // 配置实例拦截器，与 axios 的配置一致
  request.interceptors.request.use(sharedInterceptorsRequest);
  request.interceptors.response.use(sharedInterceptorsResponse);
  request.groupAPI.interceptors.request.use(sharedInterceptorsRequest);
  request.messageAPI.interceptors.request.use(sharedInterceptorsRequest);
  // request.pcmAPI.interceptors.request.use(sharedInterceptorsRequest);
  // request.groupAPI.interceptors.response.use(sharedInterceptorsResponse);

  // #endregion
};
export default requestRegister;
