/**
 * @author MKF
 * @desc
 * @date 2023/9/1
 */
export function isFunction(val: unknown) {
  return typeof val === 'function';
}

export function isValidElement(element: any) {
  if (Array.isArray(element) && element.length === 1) {
    element = element[0];
  }
  return element && element.__v_isVNode && typeof element.type !== 'symbol'; // remove text node
}

// 是否为非空数组
export function isNonEmptyArray(value: any) {
  return Array.isArray(value) && value.length > 0;
}

export const isValid = (value: any): boolean => {
  return value !== undefined && value !== null && value !== '';
};
