import { App } from 'vue';
import focus from './v-focus';
import throttle from './v-throttle';

const directives = {
  install(app: App) {
    app.directive('throttle', throttle);
    app.directive('focus', focus);
  }
};

export default directives;
