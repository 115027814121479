// 组织和车场
export type IType = 1 | 2;
interface ConfigRes {
  url: string;
  name: string;
  baseRoute: string;
}
const config: Record<string, any> = {
  'group-parking': {
    url: {
      development: 'http://localhost:8200/group-parking/',
      production_test: window.origin + '/group-parking/',
      production_prod: window.origin + '/group-parking/'
    },
    name: 'group-parking',
    baseRoute: '/unityp/group-parking'
  },
  'kos-new': {
    url: {
      development: window.origin + '/kos-new/',
      production_test: window.origin + '/kos-new/',
      production_prod: window.origin + '/kos-new/'
    },
    name: 'kos-new',
    baseRoute: '/unityp/kos-new'
  },
  'yongce-unityp-pages': {
    url: {
      development: 'http://localhost:9300/yongce-unityp-pages/',
      production_test: window.origin + '/yongce-unityp-pages/',
      production_prod: window.origin + '/yongce-unityp-pages/'
    },
    name: 'yongce-unityp-pages',
    baseRoute: '/unityp/yongce-unityp-pages'
  },
  'nkc-new': {
    url: {
      development: 'http://localhost:9300/nkc-new/',
      production_test: 'https://ts.keytop.cn/nkc-new/',
      production_prod: 'https://ipark.keytop.cn/nkc-new/'
    },
    name: 'nkc-new',
    baseRoute: '/unityp/nkc-new'
  },
  fcfront: {
    url: {
      development: 'http://localhost:8080/fcfront/',
      production_test: 'https://find-car-admin-test.keytop.cn/fcfront/',
      production_prod: 'https://find-car-admin.keytop.cn/fcfront/'
    },
    name: 'fcfront',
    baseRoute: '/unityp/fcfront'
  },
  pcm: {
    url: {
      development: 'http://localhost:9400/pcm/',
      production_test: window.origin + '/pcm/',
      production_prod: window.origin + '/pcm/'
    },
    name: 'pcm',
    baseRoute: '/unityp/pcm'
  },
  'settlefront-new': {
    url: {
      development: 'http://localhost:9200/settlefront-new/',
      production_test: 'https://ts.keytop.cn/settlefront-new/',
      production_prod: 'https://park.keytop.cn/settlefront-new/'
    },
    name: 'settlefront-new',
    baseRoute: '/unityp/settlefront-new'
  },
  'service-ticket': {
    url: {
      development: 'https://ts.keytop.cn/service-ticket/',
      production_test: window.origin + '/service-ticket/',
      production_prod: window.origin + '/service-ticket/'
    },
    name: 'service-ticket',
    baseRoute: '/unityp/service-ticket'
  },
  // 无人值守
  'ums-yc': {
    url: {
      development: 'http://localhost:8080/ums-yc/',
      production_test: window.origin + '/ums-yc/',
      production_prod: window.origin + '/ums-yc/'
    },
    name: 'ums-yc',
    baseRoute: '/unityp/ums-yc'
  },
  // 路侧
  'road-side': {
    url: {
      development: 'http://localhost:9494/road-side/',
      production_test: window.origin + '/road-side/',
      production_prod: window.origin + '/road-side/'
    },
    name: 'road-side',
    baseRoute: '/unityp/road-side'
  }
};
const appConfig: Record<string, ConfigRes> = {};
const MY_ENV = process.env.MY_ENV;
const NODE_ENV = process.env.NODE_ENV;
const env = NODE_ENV === 'production' ? `${NODE_ENV}_${MY_ENV}` : NODE_ENV;
for (const key of Object.keys(config)) {
  const { name, baseRoute } = config[key];
  appConfig[key] = {
    url: config[key].url[env],
    name,
    baseRoute
  };
}
export default appConfig;
export { ConfigRes, appConfig };

// 原统一平台基座业务，没有特殊的上下文，因此用配置的形式标识属于该项目业务。（配置未重构的页面）
// 根据配置，改成 unityp-old上下文的地址。菜单配置的地址不可靠~
// const origin = window.location.origin;
// export const unitypOldRouteName: Record<string, string> = {
//   RoleManage: `${origin}/unityp/roleManage`,
//   UserManageV2: `${origin}/unityp/userManageV2`
// };

// 需要走微应用的页面
export const microAppPageRouteNameOld: Record<string, any> = {
  // 收入分析
  incomeAnalysis: {
    name: 'IncomeAnalysis',
    path: '/group-parking/incomeAnalysis'
  },
  parkingStatistics: {
    name: 'ParkingStatistics',
    path: '/group-parking/parkingStatistics'
  },
  parkingTimeAnalysis: {
    name: 'ParkingTimeAnalysis',
    path: '/group-parking/parkingTimeAnalysis '
  },
  abnormalRelease: {
    name: 'AbnormalRelease',
    path: '/group-parking/abnormalRelease'
  },
  indicatorTrend: {
    name: 'IndicatorTrend',
    path: '/group-parking/indicatorTrend'
  }
};

// 需要微应用的路由前缀，暂时先处理永策新页面
export const microAppPageRouteNames = [
  'group-parking',
  'kos-new',
  'nkc-new',
  'pcm'
];

// 框架页面嵌入
export const old_unityp = [
  '/noticeManage',
  '/personalization',
  '/userManage',
  '/roleManage',
  '/groupManage',
  '/operateLog'
];

// 需要记录组织id
export const need_record_ids = ['BusinessStatistics'];
